import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ListType } from "../../../../../domain/list-type";

@Component({
  selector: 'app-grid-list-toggle-button',
  templateUrl: './grid-list-toggle-button.component.html'
})
export class GridListToggleButtonComponent {
  @Input() currentType: ListType = 'grid-cell';
  @Output() toggleGrid = new EventEmitter();
  @Output() toggleList = new EventEmitter();
}
