<div
  class="flex flex-col items-center relative gap-[32px] bg-tt-bg-page w-full h-full px-[60px] py-[32px]"
  *transloco="let t; read: NewsEditorConstants.TRANSLOCO_READ"
>
  <p class="text-tt-font-size_2xl sc text-tt-text-secondary">
    {{ t('preview') }}
  </p>

  <div
    class="preview-shadow top-[88px] absolute origin-top-left h-[660px] bg-white rounded-[12px] overflow-hidden"
    #previewBlock
  >
    <svg-news-editor-preview-toolbar
      class="block w-full min-h-[31px]"
    />

    <div
      class="px-[24px] pt-[20px] flex flex-col gap-[24px] max-w-full"
    >
      <p
        class="font-medium text-tt-font-size_6xl text-tt-text-main"
      >
        {{ t('news') }}
      </p>

      <app-category-skeleton
        class="block max-w-full overflow-hidden"
      />

      <div
        class="mt-[8px] w-full grid flex-wrap"
        #newsList
      >
        <app-news-item
          class="w-full flex justify-center"
          [disabled]="true"
          [withoutShadow]="true"
          [news]="news"
          [type]="'grid-cell'"
        />
        <app-news-item-skeleton
          *ngFor="let i of [1, 2, 3, 4, 5, 6]"
        />
      </div>
    </div>
  </div>
</div>
