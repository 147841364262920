<div
  class="fixed left-0 top-0 z-[100] w-screen h-screen bg-white"
  *transloco="let t; read: NewsEditorConstants.TRANSLOCO_READ"
>
  <div class="h-[60px] w-full pl-[16px] pr-[24px] flex items-center justify-between border-tt-border-default border-[1px]">
    <div class="flex gap-[16px] items-center">
      <app-core-button-icon
        (onClick)="onCloseSidebar.emit()"
      >
        <svg-x-mark class="icon"/>
      </app-core-button-icon>

      <p class="text-tt-font-size_3xl text-tt-text-main">{{ t('publish-settings') }}</p>
    </div>

    <app-core-button
      (onClick)="onPublish.emit()"
    >
      {{ t('publish') }}
    </app-core-button>
  </div>

  <div class=" w-full flex h-full">
    <div
      class="p-[24px] flex flex-col gap-[16px] border-r border-tt-border-default"
      resizeRightX
      [baseWidth]="632"
      [maxWidth]="9999"
      [minWidth]="400"
      [resizeTooltip]="'Cделал для более удобного тестирования, уберу как заапрувишь'"
    >
      <app-core-input-field
        [label]="t('title-label')"
        inputVariant="standard"
        [placeholder]="t('title-placeholder')"
        errorShowType="onUnFocused"
        error="{{ titleError }}"
        [charsCounterMax]="128"
        [defaultValue]="news.title"
        [value]="news.title"
        [required]="true"
        (onChange)="onChangeTitle.emit($event)"
        #publishInput
      />

      <app-core-textarea-field
        [label]="t('description-label')"
        variant="standard"
        [placeholder]="t('description-placeholder')"
        errorShowType="onUnFocused"
        error="{{ descriptionError }}"
        [height]="92"
        [charsCounterMax]="180"
        [defaultValue]="news.description"
        [value]="news.description"
        [isResizable]="false"
        [autocomplete]=""
        [required]="true"
        (onChange)="onChangeDescription.emit($event)"
        #publishInput
      />

      <app-input-news-image
        [newsId]="news.id"
        [imageId]="news.imageId"
        (imageUpload)="onChangeImageId.emit($event)"
      />
    </div>

    <app-news-preview
      class="block w-full h-full"
      [news]="news"
    />
  </div>
</div>
