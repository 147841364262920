import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { ToastsService } from "src/app/core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "src/app/core/components/toast-alert/toast-alert.component";
import { Validator } from "../../../../../../../core/validators/validator";
import { SpacesConstants } from "../../../common/spaces-constants";
import { translate, TranslocoService } from "@jsverse/transloco";
import { KnowledgeBaseConstants } from "../../../../../common/knowledge-base-constants";

@Component({
  selector: 'create-space-modal',
  templateUrl: './create-space-modal.component.html',
  styleUrls: ['../../../common/spaces.component.scss']
})
export class CreateSpaceModalComponent{
  @Input() isCreating: boolean = false;

  @Output() createClicked = new EventEmitter<string>()
  @Output() closeClicked = new EventEmitter()

  spaceName: string = ''
  spaceNameError: string | null = null

  constructor(
    private toastsService: ToastsService,
    @Inject('SpaceTitleValidator')
    private spaceTitleValidator: Validator,
    private translocoService: TranslocoService,
  ){
    translocoService.langChanges$.subscribe(() => {
      if(this.spaceNameError != null){
        this.spaceNameChange(this.spaceName)
      }
    })
  }

  spaceNameChange(str: string){
    const error = this.spaceTitleValidator.validate(str)
    this.spaceNameError = error ? translate(SpacesConstants.TRANSLOCO_READ + "." + error, {maxLength: KnowledgeBaseConstants.SPACE_TITLE_MAX_LENGTH}) : null;
    this.spaceName = str
  }

  create(){
    this.spaceNameError = this.spaceTitleValidator.validate(this.spaceName)
    if(this.spaceNameError){
      this.toastsService.createToast({
        title: translate(SpacesConstants.TRANSLOCO_READ + '.invalid-space-name-error-title'),
        description: translate(SpacesConstants.TRANSLOCO_READ + '.invalid-space-name-error-description'),
        state: ToastState.ERROR
      })
    }
    else{
      this.createClicked.emit(this.spaceName)
    }
  }

  protected readonly SpacesConstants = SpacesConstants;
  protected readonly KnowledgeBaseConstants = KnowledgeBaseConstants;
}
