import {
  AfterViewInit,
  Component,
  ElementRef, HostListener,
  Input,
  Renderer2,
  ViewChild
} from "@angular/core";
import { TaskEntity, TaskExecutor, TaskPriority, TaskType } from "../task-item/task-item.component";
import {
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { AgilesScrollService } from "../../../../../data/agiles-scroll.service";
import { DropTaskData, SelectTaskData, TaskDragStartData } from "../table-row/table-row.component";

@Component({
  selector: 'app-tasks-table',
  templateUrl: './tasks-table.component.html',
  styleUrls: [
    './tasks-table.component.scss',
    '../../common/agiles-desk.component.scss'
  ],
})
export class TasksTableComponent
  implements AfterViewInit
{
  @Input({required: true}) table!: TasksTableEntity

  @ViewChild('tableHeadBox') tableHeadBox!: ElementRef
  @ViewChild('tableHead') tableHead!: ElementRef
  @ViewChild('tableEl') tableEl!: ElementRef
  @ViewChild('tasksToolbar', {read: ElementRef}) tasksToolbarEl!: ElementRef

  private resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(entry => {
      this.resizeTable()
    })
  })

  leftScroll: number = 0;
  topScroll: number = 0;
  selectedTaskEntities: SelectedTaskEntity[] = []
  taskNumber = 1
  draggingTask: TaskEntity | null = null
  isDragCanceled: boolean = false;

  MOCK_TABLE: TaskEntity = {
    number: 1,
    projectTag: 'TTP',
    title: 'Надо делать дела',
    description: 'Недела делать не надо',
    priority: TaskPriority.NORMAL,
    type: TaskType.TASK,
    selectType: 'unselect'
  }

  constructor(
    private renderer: Renderer2,
    private agilesScrollService: AgilesScrollService,
  ) {
    this.agilesScrollService.getScrollableElement().subscribe((element: HTMLElement | null) => {
      if(element){
        element.addEventListener('scroll', (event: Event) => {
          const element = event.target as HTMLElement
          this.topScroll = element.scrollTop
          this.setTableHeadPosition()
        })
      }
    })
  }

  ngAfterViewInit(): void {
    this.resizeObserver.observe(this.tableEl.nativeElement)
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(event: KeyboardEvent) {
    event.preventDefault()
    if(this.draggingTask != null){
      this.isDragCanceled = true
      this.draggingTask = null
    }
    this.selectedTaskEntities = []
  }

  drop(data: DropTaskData) {
    let findInSelected = this.selectedTaskEntities.find(
      selected => data.event.item.data.number == selected.task.number);
    if(findInSelected){
      let currentIndex = data.event.currentIndex
      this.selectedTaskEntities.forEach((selectedTask) =>{
        if(data.event.container.data.cell.tasks == selectedTask.fromList){
          moveItemInArray(
            data.event.container.data.cell.tasks,
            selectedTask.fromList.findIndex((task) => task == selectedTask.task),
            currentIndex
          );
        }
        else {
          transferArrayItem(
            selectedTask.fromList,
            data.event.container.data.cell.tasks,
            selectedTask.fromList.findIndex((task) => task == selectedTask.task),
            currentIndex,
          );
          selectedTask.fromList = data.event.container.data.cell.tasks
        }
        currentIndex++
      })
    } else if (data.event.previousContainer === data.event.container) {
      moveItemInArray(data.event.container.data.cell.tasks, data.event.previousIndex, data.event.currentIndex);
    } else {
      transferArrayItem(
        data.event.previousContainer.data.cell.tasks,
        data.event.container.data.cell.tasks,
        data.event.previousIndex,
        data.event.currentIndex,
      );
    }

    this.draggingTask = null
    data.column.isDragOver = false
  }

  addSelectedTask(data: SelectTaskData) {
    if(data.singleSelect){
      this.selectedTaskEntities = [{
        fromList: data.column,
        task: data.task
      }]
    } else {
      const findIndex = this.selectedTaskEntities.findIndex(selected => selected.task == data.task)
      if(findIndex != -1)
      {
        const newList = this.selectedTaskEntities.slice()
        newList.splice(findIndex, 1)
        this.selectedTaskEntities = newList;
      }
      else {
        const newList = this.selectedTaskEntities.slice()
        newList.push(
          {
            fromList: data.column,
            task: data.task
          }
        );
        this.selectedTaskEntities = newList
      }
    }
  }

  resizeTable(){
    const width = this.tableEl.nativeElement.offsetWidth;
    this.renderer.setStyle(this.tableHeadBox.nativeElement, 'width', `${width}px`);
    this.renderer.setStyle(this.tasksToolbarEl.nativeElement, 'width', `${width}px`);
  }

  scrollTable(event: Event) {
    const scrollElement = event.target as HTMLElement
    this.leftScroll = scrollElement.scrollLeft
    this.setTableHeadPosition()
  }

  private setTableHeadPosition(){
    if(this.topScroll >= 90){
      this.renderer.setStyle(this.tableHead.nativeElement, 'transform', `translateX(${-this.leftScroll}px)`);
      this.renderer.setStyle(this.tableHeadBox.nativeElement, 'top', '48px')
      this.renderer.setStyle(this.tableHeadBox.nativeElement, 'position', 'fixed')
    }
    else{
      this.renderer.removeStyle(this.tableHeadBox.nativeElement, 'top', )
      this.renderer.removeStyle(this.tableHeadBox.nativeElement, 'position')
      this.renderer.removeStyle(this.tableHead.nativeElement, 'transform')
    }
  }

  pushNew(tasks: TaskEntity[]) {
    tasks.push({
      ...this.MOCK_TABLE,
      number: this.taskNumber
    })
    this.taskNumber++
  }

  unSelect(){
    this.selectedTaskEntities = []
  }

  deleteSelected(){
    this.table = {...this.table,
      rows: this.table.rows.map((row) => {
        return {
          ...row,
          tasksCells: row.tasksCells.map(column => {
            return {
              ...column,
              tasks: column.tasks.filter(task => !this.selectedTaskEntities.find(selected => selected.task == task)) }
          })
        }
      })
    }
    this.unSelect()
  }

  dragStart(data: TaskDragStartData){
    data.taskCell.isDragOver = true
    this.isDragCanceled = false
    sessionStorage.setItem('isTasksDragCancelled', 'false')
    this.draggingTask = data.taskEntity
  }

  scrollY(delta: number){
    this.agilesScrollService.scrollElement(delta)
  }
}

export interface TasksTableEntity{
  showUnassigned: boolean
  columns: TasksStatusEntity[]
  rows: TasksTableRowEntity[]
}

export interface TasksStatusEntity {
  id: string
  name: string,
  isCompleted: boolean,
  isDeletable: boolean
  isOpen: boolean
}

export interface TasksTableRowEntity{
  isOpen: boolean
  executor: TaskExecutor | null,
  countTasks: number,
  tasksCells: TasksCell[]
}

export interface TasksCell {
  isDragOver: boolean
  isCompleted: boolean
  tasks: TaskEntity[]
}

export interface SelectedTaskEntity {
  fromList: TaskEntity[]
  task: TaskEntity
}
