import { ListType } from "../domain/list-type";
import { Renderer2 } from "@angular/core";

export function changeNewsListByType(type: ListType, renderer: Renderer2, listElement: HTMLElement){
  switch (type){
    case "grid-cell":
      renderer.setStyle(
        listElement,
        'grid-template-columns',
        'repeat(3, minmax(0, 1fr))'
      )
      renderer.setStyle(
        listElement,
        'gap',
        '40px'
      )
      break
    case "grid-cell-full":
      renderer.setStyle(
        listElement,
        'grid-template-columns',
        'repeat(2, minmax(0, 1fr))'
      )
      renderer.setStyle(
        listElement,
        'gap',
        '24px'
      )
      break
    case "full":
      renderer.setStyle(
        listElement,
        'grid-template-columns',
        'repeat(1, minmax(0, 1fr))'
      )
      renderer.setStyle(
        listElement,
        'gap',
        '24px'
      )
      break
  }
}
