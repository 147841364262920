<div
  class="rounded-[8px] flex w-max border border-tt-border-default bg-white h-[44px]"
  *transloco="let t; read: NewsListConstants.TRANSLOCO_READ"
>
  <div
    class="absolute invisible"
    #checkWidthPlaceholder
  >
  </div>
  <button
    class="categories-item border-l-0"
    (click)="select('all-news')"
    #allNewsButton
  >
    <div
      *ngIf="selected === 'all-news'"
      class="categories-item-selected rounded-l-[8px]"
    ></div>
    {{ t('all-news') }}
  </button>

  <button
    *ngFor="let item of shownCategories, let i = index"
    class="categories-item"
    [class.border-r-0]="i === shownCategories.length -1 && hideCategories.length === 0"
    (click)="select(item)"
  >
    <div
      *ngIf="selected === item"
      class="categories-item-selected"
      [class.rounded-r-[8px]]="i === shownCategories.length -1 && hideCategories.length === 0"
    ></div>
    {{ item }}
  </button>

  <div
    [ngClass]="{
      'max-w-[0] max-h-[0] overflow-hidden': hideCategories.length === 0
    }"
  >
    <button
      class="categories-item relative flex gap-[4px] border-r-[0]"
      (click)="popupOpen = !popupOpen"
      #moreButton
    >
      <div
        *ngIf="isSelectedHide()"
        class="categories-item-selected rounded-r-[8px]"
      ></div>
      <p>{{ t('more') }}</p>
      <svg-arrow
        class="block size-[20px] transition"
        [class.rotate-90]="!popupOpen"
        [class.rotate-[270deg]]="popupOpen"
      />
    </button>

    <app-core-action-menu
      *ngIf="popupOpen"
      [sections]="getCategoriesActionMenu()"
      [selectedId]="selected"
      (elementClicked)="select($event)"

      appPopup
      (popupClose)="popupOpen = false"
    />
  </div>
</div>
