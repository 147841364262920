import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { NewsListConstants } from "../../../common/news-list-constants";
import { ActionElement, ActionSection } from "../../../../../../../core/components/action-menu/action-menu.component";

@Component({
  selector: "app-news-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"],
  host: {
    class: "block w-full"
  }
})
export class CategoriesComponent implements OnChanges, AfterViewInit {
  @Input() categories: string[] = [];
  @Input() selected: string = "all-news";

  @Output() onSelect = new EventEmitter<string>();

  @ViewChild("allNewsButton") allNewsButton?: ElementRef;
  @ViewChild("moreButton") moreButton?: ElementRef;
  @ViewChild("checkWidthPlaceholder") checkWidthPlaceholder?: ElementRef;

  popupOpen: boolean = false;
  shownCategories: string[] = [];
  hideCategories: string[] = [];

  protected readonly NewsListConstants = NewsListConstants;

  private currentWidth = 0;
  private resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(entry => {
      this.currentWidth = entry.contentRect.width;
      this.changeShownCategories(entry.contentRect.width);
    });
  });

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit(): void {
    this.resizeObserver.observe(this.el.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['categories']){
      this.changeShownCategories(this.currentWidth)
    }
  }

  select(str: string) {
    this.onSelect.emit(str)
    this.selected = str
    this.popupOpen = false
    this.cdr.detectChanges()
  }

  isSelectedHide(): boolean{
    return this.hideCategories.indexOf(this.selected) != -1
  }

  getCategoriesActionMenu(): ActionSection[]{
    const elements = this.hideCategories.map<ActionElement>(item => {
      return {
        id: item,
        name: item,
        nonLocalise: true
      }
    })

    return [{elements: elements}]
  }

  changeShownCategories(width: number){
    if(!this.allNewsButton || !this.moreButton || !this.checkWidthPlaceholder)
    {
      return
    }

    let leftSpace = width
      - this.allNewsButton.nativeElement.getBoundingClientRect().width
      - this.moreButton.nativeElement.getBoundingClientRect().width

    this.shownCategories = []
    this.hideCategories = []
    this.categories.forEach((category, index) => {
      if(leftSpace > 0)
      {
        const categoryElement = this.renderer.createElement('div') as HTMLElement
        this.renderer.addClass(categoryElement, 'categories-item')
        categoryElement.innerText = category
        this.renderer.appendChild(this.checkWidthPlaceholder!.nativeElement, categoryElement)
        leftSpace -= categoryElement.getBoundingClientRect().width
        this.renderer.removeChild(this.checkWidthPlaceholder!.nativeElement, categoryElement)
        if(leftSpace >= 0)
        {
          this.shownCategories.push(category)
        }
        else{
          this.hideCategories.push(category)
        }
      }
      else{
        this.hideCategories.push(category)
      }
    })
    this.cdr.detectChanges()
    this.cdr.reattach()
  }

  protected readonly event = event;
}
