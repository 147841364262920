<div [ngClass]="className">
  <label
    *ngIf="variant === 'standard' && label"
    class="inline-block mb-1 text-tt-font-size_l text-tt-input-field-label"
  >
    {{ label }}
    <span class="text-[#F53232]" *ngIf="required">*</span>
  </label>
  <div
    class="relative w-full"
    [ngClass]="{
      'h-tt-height-field_m': variant === 'standard'
    }"
  >
    <input
      [(ngModel)]="searchString"
      (input)="onChangeValue($event)"
      (focus)="onFocus()"
      (blur)="onUnFocus()"
      [placeholder]="placeholder"
      [ngClass]="{
        'px-3': variant === 'standard',
        'rounded-b-none': opened
      }"
      class="bg-tt-input-field-background border border-tt-border-default rounded-[8px] text-tt-font-size_l font-normal text-tt-input-field-text placeholder-tt-input-field-placeholder h-full w-full focus:outline-none"
      #autocompleteInput
    />
    <div class="absolute right-4 top-[6px] flex items-center gap-[6px]">
      <button
        *ngIf="this.onUnselect.observed && selectedItem"
        (click)="onUnselectItem($event, selectedItem!.id)"
        type="button"
        class="flex justify-center items-center h-[24px] w-[24px] hover:bg-tt-bg-default rounded-full transition"
      >
        <svg-x-mark class="block size-[24px] text-tt-icon-default" />
      </button>
      <div
        class="flex justify-center items-center h-[24px] w-[24px] rounded-full cursor-text"
        [class.rotate-180]="focused || opened"
        (click)="autocompleteInputEl.nativeElement.focus()"
      >
        <svg-bordered-arrow class="text-tt-icon-default" />
      </div>
    </div>
    <div
      *ngIf="opened"
      #autocompleteDropdown
      class="max-h-[252px] overflow-y-auto tiny-scrollbar bg-tt-bg-default border-t-0 border border-tt-border-default rounded-[8px]"
      [ngClass]="{
        'rounded-t-none': variant === 'standard'
      }"
      appPopup
      popupOffsetYSide="bottom"
      overflowSide="base"
      [isScrollClose]="false"
      [animateAppearance]="false"
      [useParentWidth]="true"
      (popupClose)="opened = false"
    >
      <div class="overflow-hidden w-full text-start">
        <button
          type="button"
          *ngFor="let item of localItems; trackBy: trackItemByFn"
          (click)="onSelectItem(item)"
          [id]="ID_PREFIX + item.id"
          class="flex items-center justify-between gap-3 text-tt-font-size_l font-normal text-tt-input-field-text cursor-pointer h-[36px] px-[16px] w-full bg-tt-bg-default hover:bg-tt-icon-button-background-hover transition"
        >
          <div
            [class.items-center]="!item.description"
            class="flex flex-row gap-3"
          >
            <div *ngIf="item.image">
              <app-core-avatar
                size="l"
                [url]="item.image.url"
                [placeholder]="item.image.placeholder"
              />
            </div>
            <div class="flex flex-col items-start">
              <span
                class="text-start text-tt-font-size_l text-tt-input-field-text"
              >
                {{ item.name }}
              </span>
              <span
                *ngIf="item.description"
                class="text-start text-tt-font-size_m text-tt-input-field-placeholder"
              >
                {{ item.description }}
              </span>
            </div>
          </div>
          <div class="w-[24px] h-[24px]">
            <div *ngIf="selectedItem && item.id === selectedItem.id">
              <svg-checkmark class="text-tt-primary" />
            </div>
          </div>
        </button>
        <div
          *ngIf="localItems.length == 0"
          class="h-[36px] flex justify-center items-center"
        >
          <p class="text-tt-font-size_l text-tt-input-field-text">Ничего не найдено</p>
        </div>
      </div>
    </div>
  </div>
  <app-field-error-message [error]="error" />
</div>
