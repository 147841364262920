import { Component } from '@angular/core';

@Component({
  selector: 'app-news-item-skeleton',
  templateUrl: './news-item-skeleton.component.html',
  styleUrls: ['./news-item-skeleton.component.scss']
})
export class NewsItemSkeletonComponent {

}
