import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, Renderer2, ViewChild } from "@angular/core";

@Component({
  selector: 'app-category-skeleton',
  templateUrl: './category-skeleton.component.html',
  styleUrls: ['./category-skeleton.component.scss']
})
export class CategorySkeletonComponent implements AfterViewInit{
  @Input() skeletonWidths: number[] = [
    120, 80, 80, 120, 100
  ]

  shownSkeletons: number[] = []

  private resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(entry => {
      this.changeShownCategories(entry.contentRect.width);
    });
  });

  constructor(
    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit(): void {
    this.resizeObserver.observe(this.el.nativeElement)
  }

  changeShownCategories(width: number){
    let leftSpace = width
    this.shownSkeletons = []
    this.skeletonWidths.forEach(skeleton => {
      leftSpace -= skeleton
      if(leftSpace >= 0)
      {
        this.shownSkeletons.push(skeleton)
      }
    })
    this.cdr.detectChanges()
  }
}
