<a
  class="rounded-[16px] w-full border-[1px] relative block bg-white border-tt-border-default hover:scale-[1.02]"
  [ngClass]="{
    'pointer-events-none cursor-auto': disabled,
    'news-item': !withoutShadow
  }"
  [routerLink]="'./'"
  [queryParams]="{'newsId': news.id}"
  [queryParamsHandling]="'merge'"
  #container
>
  <div
    class="absolute left-[24px] top-[24px] rounded-[12px] px-[8px] flex items-center bg-white"
  >
    <p class="text-tt-font-size_m text-tt-text-main">CATEGORY_MOCK</p>
  </div>

  <div class="w-full h-[144px] bg-center bg-cover rounded-t-[16px]"
    [style.background-image]="'url(' + imageUrl + ')'"
    [ngClass]="{
      'bg-[#C4C4C4]': imageUrl == ''
    }"
  ></div>

  <div
    class="p-[24px] w-full h-[calc(100%_-_144px)] flex flex-col text-left"
    #text
  >
    <p class="text-tt-font-size_m text-tt-text-secondary shrink-0">{{news.dateStr}}</p>
    <p
      class="text-tt-font-size_3xl mt-[8px] font-medium shrink-0 text-tt-text-main line-clamp-5"
    >
      <span *ngIf="!news.parsedTitle">{{news.title}}</span>
      <span
        *ngIf="news.parsedTitle"
        app-core-with-highlighting
        [text]="news.parsedTitle"
      ></span>
    </p>
    <p
      class="text-tt-font-size_l mt-[16px] text-tt-text-secondary line-clamp-1"
      #description
    >
      <span *ngIf="!news.parsedDescription">{{news.description}}</span>
      <span
        *ngIf="news.parsedDescription"
        app-core-with-highlighting
        [text]="news.parsedDescription"
      ></span>
    </p>
  </div>
</a>
