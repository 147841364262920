import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { NewsSummaryEntity } from "../../../../../domain/news-summary-entity";
import { NewsService } from "../../../../../data/news-service";
import { ListType } from "../../../../../domain/list-type";

@Component({
  selector: "app-news-item",
  templateUrl: "./news-item.component.html",
  styleUrls: ["./news-item.component.scss"]
})
export class NewsItemComponent implements AfterViewInit, OnChanges {
  @Input({required: true}) news!: NewsSummaryEntity
  @Input() disabled: boolean = false;
  @Input() type: ListType = "grid-cell"
  @Input() withoutShadow = false

  @Output() onClick = new EventEmitter<NewsSummaryEntity>()

  @ViewChild("text") text?: ElementRef;
  @ViewChild("description") description?: ElementRef;
  @ViewChild("container") container?: ElementRef;

  private prevImageId = ''
  imageUrl: string = '';
  private isViewInit: boolean = false

  constructor(
    private renderer: Renderer2,
    private newsService: NewsService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['news']){
      this.loadImage()
    }
    if(this.isViewInit){
      this.resizeDescription()
    }
  }

  changeType(type: ListType){
    this.type = type
    this.resizeDescription()
  }

  ngAfterViewInit(){
    this.isViewInit = true
    this.resizeDescription()
  }

  loadImage(){
    if(this.news.imageId && this.news.imageId != this.prevImageId)
    {
      this.prevImageId = this.news.imageId
      this.newsService.downloadImage(this.news.imageId).subscribe({
        next: (result) => {
          this.imageUrl = URL.createObjectURL(result)
        }
      })
    }
  }

  resizeDescription()
  {
    const textRect = this.text?.nativeElement.getBoundingClientRect();
    this.renderer.addClass(this.description?.nativeElement, 'line-clamp-1')
    const descriptionRect = this.description?.nativeElement.getBoundingClientRect();

    if(this.type !== 'full')
    {
      this.renderer.setStyle(this.container?.nativeElement, 'height', '376px')
      const countDescriptionLines = Math.floor((206 - (descriptionRect.top - textRect.top)) / 20);//376 -2(border) -144(картинка)-24(padding)
      this.renderer.setStyle(this.description?.nativeElement, '-webkit-line-clamp', countDescriptionLines, 1)
    } else {
      this.renderer.removeStyle(this.container?.nativeElement, 'height')
      this.renderer.removeStyle(this.description?.nativeElement, '-webkit-line-clamp')
      this.renderer.removeClass(this.description?.nativeElement, 'line-clamp-1')
    }
  }
}
