<div
  class="flex flex-col gap-[16px]"
  [ngClass]="{
    'invisible w-[0px] h-[0px] overflow-hidden': state.receiptPrice == 0
  }"
  *transloco="let t; read: PaymentConstants.TRANSLOCO_READ"
>
  <div
    class="form-block"
  >
    <div
      class="flex flex-col gap-[24px]"
    >
      <div class="w-full flex justify-center">
        <app-toggler
          class="block max-w-full"
          [selectedId]="state.selectedPaymentType"
          [toggleItems]="state.togglerItems"
          (toggle)="performAction({
            type: InvoicingModalActionTypes.TOGGLE_PAYMENT_TYPE,
            id: $event
          })"
        />
      </div>

      <div
        *ngIf="state.selectedPaymentType === 'invoice'"
        class="flex flex-col gap-[12px]"
      >
        <app-core-input-field
          inputVariant="standard"
          [label]="t('company-name-label')"
          [placeholder]="t('company-name-placeholder')"
          [required]="true"
          errorShowType="onUnFocused"
          [error]="state.organisationName.error"
          [defaultValue]="state.organisationName.value"
          (onChange)="performAction({
            type: InvoicingModalActionTypes.CHANGE_ORGANISATION_NAME,
            value: $event
          })"
        />

        <app-core-input-field
          inputVariant="standard"
          [label]="t('legal-address-label')"
          [placeholder]="t('legal-address-placeholder')"
          [required]="true"
          errorShowType="onUnFocused"
          [error]="state.legalAddress.error"
          [defaultValue]="state.legalAddress.value"
          (onChange)="performAction({
            type: InvoicingModalActionTypes.CHANGE_LEGAL_ADDRESS,
            value: $event
          })"
        />

        <div class="flex h-[40px] items-center gap-[8px]">
          <app-core-checkbox-field
            [value]="state.isISoloProprietor"
            [label]="t('sole-proprietor-label')"
            (onToggle)="performAction({
              type: InvoicingModalActionTypes.TOGGLE_I_SOLE_PROPRIETOR
            })"
          />
        </div>

        <div class="flex w-full gap-[16px]">
          <app-core-input-field
            class="w-full"
            inputVariant="standard"
            [charsCounterMax]="state.isISoloProprietor ? 12 : 10"
            [required]="true"
            [label]="t('inn-label')"
            [placeholder]="t('inn-placeholder')"
            [mask]="'000000000000'"
            errorShowType="onUnFocused"
            [error]="state.INN.error"
            [defaultValue]="state.INN.value"
            (onChange)="performAction({
              type: InvoicingModalActionTypes.CHANGE_INN,
              value: $event
            })"
          />
          <app-core-input-field
            *ngIf="!state.isISoloProprietor"
            class="w-full"
            inputVariant="standard"
            [charsCounterMax]="9"
            [label]="t('kpp-label')"
            [placeholder]="t('kpp-placeholder')"
            mask="000000000"
            [required]="true"
            errorShowType="onUnFocused"
            [error]="state.KPP.error"
            [defaultValue]="state.KPP.value"
            (onChange)="performAction({
              type: InvoicingModalActionTypes.CHANGE_KPP,
              value: $event
            })"
          />
        </div>

        <app-core-input-field
          inputVariant="standard"
          [label]="t('phone-label')"
          [placeholder]="t('phone-placeholder')"
          errorShowType="onUnFocused"
          [required]="true"
          prefix="+"
          mask="0 (000) 000-00-00||00 (000) 000-00-00||000 (000) 000-00-00"
          [error]="state.phoneNumber.error"
          [defaultValue]="state.phoneNumber.value"
          (onChange)="performAction({
            type: InvoicingModalActionTypes.CHANGE_PHONE_NUMBER,
            value: $event
          })"
        />

        <app-core-input-field
          inputVariant="standard"
          [label]="t('email-label')"
          [placeholder]="t('email-placeholder')"
          errorShowType="onUnFocused"
          [required]="true"
          [error]="state.email.error"
          [defaultValue]="state.email.value"
          (onChange)="performAction({
            type: InvoicingModalActionTypes.CHANGE_EMAIL,
            value: $event
          })"
        />
      </div>

      <app-receipt
        [resultLocal]="translateAmount"
        (onChange)="performAction({
          type: PaymentActionTypes.CHANGE_RECEIPT_PRICE,
          value: $event
        })"
      />

      <app-core-button
        className="w-full"
        size="l"
        (onClick)="onPayClicked.emit()"
      >
        <span *ngIf="state.selectedPaymentType === 'card'">{{ t('proceed-to-payment') }}</span>
        <span *ngIf="state.selectedPaymentType === 'invoice'">{{ t('get-invoice') }}</span>
      </app-core-button>
    </div>
  </div>

  <p
    class="px-[24px] text-tt-font-size_m text-tt-text-secondary"
    [textParse]="t('details', { paymentType: state.selectedPaymentType })"
    [parseElements]="[
      {
        type: 'text',
        text: t('details-pay', { paymentType: state.selectedPaymentType }),
        className: 'text-tt-primary cursor-pointer'
      },
      {
        type: 'text',
        text: t('details-license-agreement'),
        className: 'text-tt-primary cursor-pointer'
      },
      {
        type: 'text',
        text: t('details-policy-of-personal-data'),
        className: 'text-tt-primary cursor-pointer'
      },
    ]"
  ></p>
</div>
