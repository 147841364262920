import { AfterViewInit, Component, ElementRef, QueryList, Renderer2, ViewChild, ViewChildren } from "@angular/core";
import { NewsListState } from "./state/news-list-state";
import { NewsListReducer } from "./state/news-list-reducer";
import { NewsListExecutor } from "./state/news-list-executor";
import { Store } from "../../../../../core/mvi/store";
import { NewsListAction, NewsListActionTypes } from "./state/news-list-action";
import { NewsListResultAction } from "./state/news-list-result-action";
import { NewsConstants } from "../../../common/news-constants";
import { MainRoutesPaths } from "../../../../../routes/main-routes";
import { HeaderService } from "../../../../main/data/header-service";
import { NavTab } from "../../../../../core/components/nav-tabs/nav-tabs.component";
import { Tabs } from "../../../../main/presentation/state/main-state";
import { provideTranslocoScope } from "@jsverse/transloco";
import { NewsListConstants } from "../common/news-list-constants";
import { YooptaEditorConstants } from "../../../../../core/components/yoopta-editor/common/yoopta-editor-constants";
import { NewsItemComponent } from "./components/news-item/news-item.component";
import { ListType } from "../../../domain/list-type";
import { changeNewsListByType } from "../../../utils/change-news-list-by-type";

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss', '../../../../main/presentation/main.component.scss'],
  providers: [
    NewsListState,
    NewsListReducer,
    NewsListExecutor,
    provideTranslocoScope({ scope: 'news/list', alias: NewsListConstants.TRANSLOCO_READ }),
    provideTranslocoScope({ scope: "core/editor", alias: YooptaEditorConstants.TRANSLOCO_READ })
  ],
  host: {
    class: 'block',
  }
})
export class NewsListComponent extends Store<
  NewsListState,
  NewsListExecutor,
  NewsListAction,
  NewsListResultAction
> implements AfterViewInit
{
  @ViewChild('scrollable', { read: ElementRef }) scrollableEl?: ElementRef
  @ViewChild('newsList', { read: ElementRef }) newsList?: ElementRef
  @ViewChildren('newsListItem') newsListItems!: QueryList<NewsItemComponent>

  readonly NAV_TABS: NavTab[] = [
    {
      id: Tabs.NEWS,
      url: '/' + MainRoutesPaths.NEWS,
      title: 'main'
    }
  ]

  blockWidth = 0

  private resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(entry => {
      this.blockWidth = entry.contentRect.width
      this.resizeNewsList()
    });
  });

  constructor(
    state: NewsListState,
    executor: NewsListExecutor,
    reducer: NewsListReducer,
    private headerService: HeaderService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    super(state, executor, reducer);

    headerService.ChangeStyle('bg-tt-bg-page')
    headerService.ChangeBreadCrumbs([])
  }

  ngAfterViewInit(): void {
    this.resizeObserver.observe(this.el.nativeElement)
  }

  resizeNewsList(){
    if(!this.newsList){
      return
    }

    let type: ListType = "grid-cell"
    if(this.blockWidth < 1256 && this.blockWidth >= 936){
      type = "grid-cell-full"
    } else if(this.blockWidth < 936){
      type = "full"
    }
    changeNewsListByType(type, this.renderer, this.newsList.nativeElement)

    this.newsListItems.forEach((item)=>{
      item.changeType(type)
    })
    this.performAction({
      type: NewsListActionTypes.CHANGE_LIST_TYPE,
      listType: type
    })
  }

  onScroll(){
    if(this.scrollableEl){
      if(this.scrollableEl.nativeElement.scrollTop > 0){
        this.headerService.ChangeStyle('bg-tt-bg-page border-b-[1px] border-tt-border-default')
      }
      else{
        this.headerService.ChangeStyle('bg-tt-bg-page')
      }
      if(this.scrollableEl.nativeElement.scrollTop > 50){
        this.headerService.ChangeBreadCrumbs([
          {
            name: NewsConstants.NEWS,
            href: '/' + MainRoutesPaths.NEWS
          }
        ])
      }
      else{
        this.headerService.ChangeBreadCrumbs([])
      }
    }
  }

  protected readonly NewsListActionTypes = NewsListActionTypes;
  protected readonly NewsConstants = NewsConstants;
  protected readonly NewsListConstants = NewsListConstants;
}
