import { Component, Input } from '@angular/core';
import { NgClass, NgIf } from "@angular/common";

@Component({
  selector: "app-core-avatar",
  templateUrl: "./avatar.component.html",
  imports: [
    NgClass,
    NgIf
  ],
  standalone: true
})
export class AvatarComponent {
  @Input() className: string | string[] = [];
  @Input() placeholder = '';
  @Input() url?: string | null;
  @Input() size: 's' | 'm' | 'ml' | 'l' | 'lx' | 'xl' | '2xl' | '3xl' | '5xl' = 'm';
  @Input() online = false;
  @Input() rounded: 'l' | 'full' = 'full';
  @Input() variant: 'neutral' | 'primary' = 'neutral';
  @Input() haveContent: boolean = false;
  @Input() bgRGB: string = ''
  @Input() borderSize: number = 0
}
