import { SettingsMainState } from "./settings-main-state";
import { SettingsMainAction, SettingsMainActionTypes } from "./settings-main-action";
import { SettingsMainResultAction, SettingsMainResultActionTypes } from "./settings-main-result-action";
import { Inject, Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { HeaderService } from "../../../../../main/data/header-service";
import { Validator } from "../../../../../../core/validators/validator";
import { SettingsMainConstants } from "../../common/settings-main-constants";
import { CompanyService } from "../../../../../../core/services/company-service/company-service";
import { ToastsService } from "../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../core/components/toast-alert/toast-alert.component";
import { error } from "@angular/compiler-cli/src/transformers/util";
import { translate, TranslocoService } from "@jsverse/transloco";
import { SettingsConstants } from "../../../../common/settings-constants";

@Injectable()
export class SettingsMainExecutor extends Executor<
  SettingsMainState,
  SettingsMainAction,
  SettingsMainResultAction
> {
  constructor(
    private headerService: HeaderService,
    @Inject('CompanyTitleValidator')
    private companyTitleValidator: Validator,
    private companyService: CompanyService,
    private toastsService: ToastsService,
    private translocoService: TranslocoService
  ) {
    super();
  }
  override init(
    reducer: Reducer<SettingsMainState, SettingsMainResultAction>,
    getState: () => SettingsMainState,
    onReduced: (state: SettingsMainState) => void,
  ) {
    super.init(reducer, getState, onReduced);

    this.headerService.header.subscribe((header)=>{
      this.reduce({
        type: SettingsMainResultActionTypes.INIT,
        organisationId: '-1',
        organisationTitle: header.organisationName
      })
    })

    this.translocoService.langChanges$.subscribe(() => {
      if(this.getState().organisationTitleError != null){
        this.handleChangeCompanyName(this.getState().newOrganisationTitle)
      }
    })
  }

  execute(action: SettingsMainAction) {
    switch (action.type) {
      case SettingsMainActionTypes.DELETE:
        this.companyService.deleteCompany(this.getState().organisationId).subscribe({
          error: err => {
            this.toastsService.createToast({
              title: translate(SettingsMainConstants.TRANSLOCO_READ + '.delete-company-error-title'),
              description: translate(SettingsMainConstants.TRANSLOCO_READ + '.delete-company-error-description'),
              state: ToastState.ERROR
            })
          }
        })
        break
      case SettingsMainActionTypes.CHANGE_ORGANISATION_NAME:
        this.handleChangeCompanyName(action.value)
        break;
      case SettingsMainActionTypes.CANCEL_TITLE:
        this.reduce({
          type: SettingsMainResultActionTypes.CANCEL_TITLE
        })
        break
      case SettingsMainActionTypes.SAVE_TITLE:
        if(!this.getState().organisationTitleError)
        {
          const prevTitle = this.getState().organisationTitle
          this.headerService.changeOrganisationName(this.getState().newOrganisationTitle)

          this.reduce({
            type: SettingsMainResultActionTypes.CHANGE_IS_PAGE_LOADING,
            value: true
          })

          this.companyService.updateCompany(this.getState().newOrganisationTitle).subscribe({
            next: () => {
              this.reduce({
                type: SettingsMainResultActionTypes.CHANGE_IS_PAGE_LOADING,
                value: false
              })
            },
            error: result => {
              this.headerService.changeOrganisationName(prevTitle)
              this.toastsService.createToast({
                title: translate(SettingsMainConstants.TRANSLOCO_READ + '.update-company-error-title'),
                description: translate(SettingsMainConstants.TRANSLOCO_READ + '.update-company-error-description'),
                state: ToastState.ERROR
              })
              this.reduce({
                type: SettingsMainResultActionTypes.CHANGE_IS_PAGE_LOADING,
                value: false
              })
            }
          })
        }
        break
      case SettingsMainActionTypes.CHANGE_NAV_ITEM:
        this.reduce({
          type: SettingsMainResultActionTypes.INIT,
          organisationId: this.getState().organisationId,
          organisationTitle: this.getState().organisationTitle
        })
        this.reduce({
          type: SettingsMainResultActionTypes.CHANGE_NAV_ITEM,
          navItem: action.navItem
        })
        break;
      case SettingsMainActionTypes.CHANGE_LANGUAGE:
        this.reduce({
          type: SettingsMainResultActionTypes.CHANGE_LANGUAGE,
          language: SettingsMainConstants.LANGUAGE_DROPDOWNS.find((item)=> item.id == action.id)
        })
        break
      case SettingsMainActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY:
        this.reduce({
          type: SettingsMainResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
          value: action.value
        })
        break
    }
  }

  private handleChangeCompanyName(value: string) {
    const error = this.companyTitleValidator.validate(value)
    this.reduce({
      type: SettingsMainResultActionTypes.CHANGE_ORGANISATION_NAME,
      newOrganisationTitle: value,
      organisationTitleError: error ?
        translate(
          SettingsMainConstants.TRANSLOCO_READ + '.' + error,
          {
            maxLength: SettingsConstants.COMPANY_NAME_MAX_LENGTH
          }
        ) : null
    })
  }
}
