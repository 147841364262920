import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges
} from "@angular/core";

@Directive({
  selector: "[tooltip]",
  standalone: true
})
export class TooltipDirective {
  @Input() tooltip?: string;
  @Input() tooltipDuration: number = 1500

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.setStyle(elementRef.nativeElement, 'position', 'relative')
  }

  public showTooltip(){
    const tooltipElement = this.renderer.createElement('div');
    this.renderer.addClass(tooltipElement, 'p-[4px]')
    this.renderer.addClass(tooltipElement, 'rounded-[4px]')
    this.renderer.addClass(tooltipElement, 'bg-[#616161]')
    this.renderer.addClass(tooltipElement, 'text-[#FFF]')
    this.renderer.addClass(tooltipElement, 'text-[12px]')
    this.renderer.addClass(tooltipElement, 'leading-[16px]')
    this.renderer.addClass(tooltipElement, 'font-tt-font-tooltip')
    this.renderer.addClass(tooltipElement, 'tracking-[0.033em]');
    this.renderer.addClass(tooltipElement, 'text-nowrap');
    this.renderer.setStyle(tooltipElement, 'position', 'absolute')
    this.renderer.setStyle(tooltipElement, 'bottom', '-20px')
    const parentRect = this.elementRef.nativeElement.getBoundingClientRect() as DOMRect
    this.renderer.setStyle(tooltipElement, 'left', parentRect.width / 2 + 'px')
    this.renderer.addClass(tooltipElement, 'translate-x-[-50%]')
    tooltipElement.innerText = this.tooltip
    this.renderer.appendChild(this.elementRef.nativeElement, tooltipElement)
    setTimeout(() => this.renderer.removeChild(this.elementRef.nativeElement, tooltipElement), this.tooltipDuration)
  }
}
