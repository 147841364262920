import { Component, ElementRef, ViewChild } from '@angular/core';
import { EmployeesConstants } from "../common/employees-constants";
import { HeaderService } from '../../main/data/header-service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MainRoutesPaths } from 'src/app/routes/main-routes';
import { EmployeesRoutesPath } from 'src/app/routes/employees-routes';
import { filter } from 'rxjs';
import { NavTab } from "../../../core/components/nav-tabs/nav-tabs.component";
import { Tabs } from "../../main/presentation/state/main-state";
import { provideTranslocoScope } from "@jsverse/transloco";

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: [
    './employees.component.scss',
    '../../main/presentation/main.component.scss'
  ],
  providers: [
    provideTranslocoScope({ scope: 'employees/main', alias: EmployeesConstants.TRANSLOCO_READ })
  ]
})

export class EmployeesComponent
{
  @ViewChild('scrollable', { read: ElementRef }) scrollableEl?: ElementRef

  navTabs: NavTab[] = []

  constructor(
    private headerService: HeaderService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    headerService.ChangeStyle('bg-tt-bg-page')
    headerService.ChangeBreadCrumbs([])

    const tabs = localStorage.getItem('tabs')
    if(tabs){
      const tabsArray = JSON.parse(tabs) as string[]
      this.navTabs = EmployeesConstants.NAV_TABS.filter((tab) => tabsArray.includes(tab.id))
      if(route.children.length == 0){
        if(tabs.includes(Tabs.EMPLOYEES))
        {
          router.navigateByUrl(`${MainRoutesPaths.EMPLOYEES}/${EmployeesRoutesPath.LIST}`)
        }
        else if (tabs.includes(Tabs.ROLE)){
          router.navigateByUrl(`${MainRoutesPaths.EMPLOYEES}/${EmployeesRoutesPath.ROLES}`)
        }
      }
      router.events.pipe(
        filter(event => event instanceof NavigationStart)
      ).subscribe((event) => {
        if(event instanceof NavigationStart && event.url.replaceAll('/', '') == MainRoutesPaths.EMPLOYEES){
          if(tabs.includes(Tabs.EMPLOYEES))
          {
            router.navigateByUrl(`${MainRoutesPaths.EMPLOYEES}/${EmployeesRoutesPath.LIST}`)
          }
          else if (tabs.includes(Tabs.ROLE)){
            router.navigateByUrl(`${MainRoutesPaths.EMPLOYEES}/${EmployeesRoutesPath.ROLES}`)
          }
        }
      });
    }
  }


  onScroll(){
    if(this.scrollableEl){
      if(this.scrollableEl.nativeElement.scrollTop > 0){
        this.headerService.ChangeStyle('bg-tt-bg-page border-b-[1px] border-tt-border-default')
      }
      else{
        this.headerService.ChangeStyle('bg-tt-bg-page')
      }
      if(this.scrollableEl.nativeElement.scrollTop > 50){
        this.headerService.ChangeBreadCrumbs([
          {
            name: EmployeesConstants.EMPLOYEES,
            href: '/' + MainRoutesPaths.EMPLOYEES
          }
        ])
      }
      else{
        this.headerService.ChangeBreadCrumbs([])
      }
    }
  }

  protected readonly EmployeesConstants = EmployeesConstants;
}

