<div
  class="h-screen flex flex-col bg-white"
  *ngIf="!state.alreadyEditModalVisibility"
>
  <div
    class="h-[60px] w-screen pl-[16px] pr-[24px] fixed
      flex justify-between items-center z-50
      border-[1px] border-tt-border-default"
    *transloco="let t; read: NewsEditorConstants.TRANSLOCO_READ"
  >
    <div class="flex gap-[8px] items-center">
      <app-core-button-icon
        (click)="performAction({
          type: NewsEditorActionTypes.BACK_CLICKED,
        })"
      >
        <svg-back-arrow
          class="block size-[24px]"
        />
      </app-core-button-icon>

      <p class="text-tt-font-size_2xl text-tt-text-main">
        <span *ngIf="state.editorType === 'create'">{{ t('creating-news') }}</span>
        <span *ngIf="state.editorType === 'edit'">{{ t('editing-news') }}</span>
      </p>
    </div>

    <app-core-button
      (onClick)="performAction({
        type: NewsEditorActionTypes.CHANGE_PUBLISH_SIDEBAR_VISIBLE,
        value: true
      })"
    >
      {{ t('move-to-publish') }}
    </app-core-button>
  </div>

  <div
    class="h-[calc(100vh_-_60px)] mt-[60px] overflow-y-auto tiny-scrollbar"
    id="yoopta-editor-scroll"
  >
    <div
      class="w-full flex justify-center mt-[20px]"
      *ngIf="state.isEditorLoading"
    >
      <mat-spinner class="w-[48px] h-[48px]"></mat-spinner>
    </div>

    <app-yoopta-editor
      class="flex-grow-1 w-full flex justify-center pt-[40px]"
      [class.invisible]="state.isEditorLoading"
      [longreadId]="state.currentNews.longreadId"
      [title]="state.currentNews.title"
      (titleChange)="performAction({
        type: NewsEditorActionTypes.CHANGE_TITLE,
        value: $event
      })"
      (startLoading)="performAction({
        type: NewsEditorActionTypes.CHANGE_IS_EDITOR_LOADING,
        value: true
      })"
      (endLoading)="performAction({
        type: NewsEditorActionTypes.CHANGE_IS_EDITOR_LOADING,
        value: false
      })"
      #editor
    />
  </div>

</div>

<ng-container
  *transloco="let t; read: NewsEditorConstants.TRANSLOCO_READ"
>
  <app-news-publish-settings
    *ngIf="state.isPublishSideBarOpen"
    [news]="state.currentNews"
    [titleError]="state.titleError"
    [descriptionError]="state.descriptionError"
    (onChangeTitle)="performAction({
      type: NewsEditorActionTypes.CHANGE_TITLE,
      value: $event
    })"
    (onChangeDescription)="performAction({
      type: NewsEditorActionTypes.CHANGE_DESCRIPTION,
      value: $event
    })"
    (onChangeImageId)="performAction({
      type: NewsEditorActionTypes.CHANGE_IMAGE_ID,
      value: $event
    })"
    (onCloseSidebar)="performAction({
      type: NewsEditorActionTypes.CHANGE_PUBLISH_SIDEBAR_VISIBLE,
      value: false
    })"
    (onPublish)="performAction({
      type: NewsEditorActionTypes.PUBLISH,
    })"
  />

<app-core-alert-modal
  *ngIf="state.afkModalVisibility"
  [title]="t('are-you-here')"
  type="info"
  closeActionType="confirm"
  [isLoading]="state.isLoadingAfkModal"
  [hideContentOnLoad]="true"
  [cancelButtonText]="t('save-and-exit')"
  [confirmButtonText]="t('stay')"
  (onCancelClicked)="performAction({
    type: NewsEditorActionTypes.SAVE_AND_MOVE
  })"
  (onConfirmClicked)="performAction({
    type: NewsEditorActionTypes.CLOSE_AFK_MODAL
  })"
>
  <app-core-stopwatch
    class="w-full flex justify-center"
    type="moveBack"
    [time]="300"
    [stopTime]="0"
  />
  <p class="mt-[8px]">{{ t('are-you-here-description') }}</p>
</app-core-alert-modal>

<div
  class="w-screen h-screen absolute left-0 top-0 bg-white z-50 flex items-center justify-center"
  *ngIf="state.isLoading"
>
  <mat-spinner
    class="size-[40px]"
  />
</div>

<app-core-alert-modal
  *ngIf="state.alreadyEditModalVisibility"
  [title]="t('already-edit')"
  type="info"
  closeActionType="confirm"
  [confirmButtonText]="t('close')"
  (onConfirmClicked)="performAction({
    type: NewsEditorActionTypes.BACK
  })"
>
  <p>{{ t('already-edit-description') }}</p>
</app-core-alert-modal>

<app-core-loading-shading
  *ngIf="state.isLoading"
  class="bg-white"
/>

<app-core-loading-shading
  *ngIf="state.isPageLoading"
/>

</ng-container>
