<ng-container
  *transloco="let t; read: RoleConstants.TRANSLOCO_READ"
>
  <div class="child-routes-size px-[24px] tiny-scrollbar overflow-y-scroll"
    (scroll)="onScroll()"
    #scrollable
  >
    <div
      class="mt-[44px] w-full flex justify-center"
      *ngIf="state.isLoading"
    >
      <mat-spinner class="size-[64px]"></mat-spinner>
    </div>
    <div
      class="w-full"
      *ngIf="!state.isLoading"
    >
      <app-core-page-header
        *transloco="let tBaseRoles; read: EmployeesConstants.BASE_ROLES_TRANSLOCO_READ"
        textSize="5xl"
        [title]="state.isCustom ? state.mainData.name : tBaseRoles(state.mainData.name)"
      >
        {{state.isCustom ? state.mainData.description : tBaseRoles(state.mainData.description)}}
      </app-core-page-header>

      <div class="w-full flex flex-col items-center py-[24px]">
        <app-core-settings-container>
          <app-core-settings-nav-container
            [title]="t('nav-title')"
          >
            <a
              [routerLink]="'./'"
              [queryParams]="{
              nav: RoleNavItems.MAIN
            }"
            >
              <app-core-settings-nav-item
                [isSelected]="state.currentNavItem == RoleNavItems.MAIN"
              >
                <svg-settings-home/>
                <p>{{ t('main') }}</p>
              </app-core-settings-nav-item>
            </a>

            <a
              [routerLink]="'./'"
              [queryParams]="{
              nav: RoleNavItems.PERMISSIONS
            }"
            >
              <app-core-settings-nav-item
                [isSelected]="state.currentNavItem == RoleNavItems.PERMISSIONS"
              >
                <svg-role-accesses/>
                <p>{{ t('permissions') }}</p>
              </app-core-settings-nav-item>
            </a>

            <a
              [routerLink]="'./'"
              [queryParams]="{
              nav: RoleNavItems.USERS
            }"
            >
              <app-core-settings-nav-item
                [isSelected]="state.currentNavItem == RoleNavItems.USERS"
              >
                <svg-person/>
                <p>{{ t('users') }}</p>
              </app-core-settings-nav-item>
            </a>

          </app-core-settings-nav-container>

          <app-role-main
            class="w-full"
            *ngIf="state.currentNavItem == RoleNavItems.MAIN"
            [data]="state.mainData"
            [isReadOnly]="state.canEdit"
            [isCustom]="state.isCustom"
            (onSave)="performAction({
              type: RoleActionTypes.SAVE_MAIN_DATA,
              data: $event
            })"
          />

          <app-role-permissions
            class="w-full"
            *ngIf="state.currentNavItem == RoleNavItems.PERMISSIONS"
            [permissions]="state.permissions"
            [isReadOnly]="!state.isCustom || !state.canEdit"
            (onSave)="performAction({
            type: RoleActionTypes.SAVE_PERMISSIONS,
            permissions: $event
          })"
          />

          <app-role-users
            *ngIf="state.currentNavItem === RoleNavItems.USERS"
            class="w-full"
            [roleId]="state.id"
          />
        </app-core-settings-container>

        <ng-container
          *ngIf="state.currentNavItem == RoleNavItems.MAIN"
        >
          <div
            *ngIf="state.isCustom"
            class="mt-[24px] max-w-[935px] w-full h-[1px] bg-tt-border-header-popup-without-alpha"
          ></div>

          <app-core-settings-button
            *ngIf="state.canDelete && state.isCustom"
            class="mt-[24px] flex justify-center"
            (onClick)="performAction({
            type: RoleActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
            value: true
          })"
          >
            <svg-trash class="min-h-[24px] min-w-[24px] max-h-[24px] max-w-[24px]"/>
            <p class="text-[14px]">{{ t('delete-role') }}</p>
          </app-core-settings-button>
        </ng-container>
      </div>
    </div>
  </div>

  <app-core-alert-modal
    *ngIf="state.isDeleteAlertVisible"
    [title]="t('delete-role')"
    type="warning"
    [cancelButtonText]="t('cancel')"
    [confirmButtonText]="t('delete')"
    [isLoading]="state.isDeleting"
    (onConfirmClicked)="performAction({
    type: RoleActionTypes.DELETE
  })"
    (onCancelClicked)="performAction({
    type: RoleActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
    value: false
  })"
  >
    <p
      [textParse]="t('delete-role-description', { roleName: state.mainData.name })"
      [parseElements]="[{
        text: state.mainData.name,
        className: 'font-medium',
        type: 'text'
      }]"
    ></p>

  </app-core-alert-modal>

  <div
    class="fixed left-0 top-0 w-screen h-screen z-50"
    *ngIf="state.isPageLoading"
  >
    <app-core-loading-shading/>
  </div>

</ng-container>
