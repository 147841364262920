import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { EmployeeService } from 'src/app/features/employees/data/employee-service';
import { EmployeesComponent } from './presentation/employees.component';
import { EmployeesListModule } from './modules/employees-list/employees-list.module';
import { EmployeesRolesModule } from './modules/employees-roles/employees-roles.module';
import { RoleModule } from "./modules/role/role.module";
import { PageHeaderComponent } from "../../core/components/page-header/page-header.component";
import { TranslocoDirective } from "@jsverse/transloco";

@NgModule({
  declarations: [
    EmployeesComponent,
  ],
  imports: [
    CommonModule,
    RouterOutlet,
    EmployeesListModule,
    EmployeesRolesModule,
    RoleModule,
    PageHeaderComponent,
    TranslocoDirective
  ],
  providers: [
    EmployeeService,
  ],
})
export class EmployeesModule {
}
