import { Executor, Reducer } from "src/app/core/mvi/store";
import { CreateRoleModalResultAction, CreateRoleModalResultActionTypes } from "./create-role-modal-result-action";
import { Inject, Injectable } from "@angular/core";
import {
  CreateRoleModalClueTexts,
  CreateRoleModalClueTitles, CreateRoleModalConstants,
  CreateRoleModalNavItems
} from "../common/create-role-modal-constants";
import { RoleService } from "src/app/core/services/role-service/role-service";
import { CreateRoleModalState, StepViewState } from "./create-role-modal-state";
import { CreateRoleModalAction, CreateRoleModalActionTypes } from "./create-role-modal-action";
import { Validator } from "src/app/core/validators/validator";
import { EmployeesRolesExecutor } from "../../../state/employees-roles-executor";
import { EmployeesRolesActionTypes } from "../../../state/employees-roles-action";
import { ToastsService } from "../../../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../../../core/components/toast-alert/toast-alert.component";
import { translate, TranslocoService } from "@jsverse/transloco";
import { EmployeesRolesConstants } from "../../../../common/employees-roles-constants";

@Injectable()
export class CreateRoleModalExecutor extends Executor<
  CreateRoleModalState,
  CreateRoleModalAction,
  CreateRoleModalResultAction
> {
  constructor(
    private roleService: RoleService,
    private rolesExecutor: EmployeesRolesExecutor,
    private toastsService: ToastsService,
    @Inject('NewRoleNameValidator')
    private roleNameValidator: Validator,
    @Inject('NewRoleDescriptionValidator')
    private roleDescriptionValidator: Validator,
    private translocoService: TranslocoService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<CreateRoleModalState, CreateRoleModalResultAction>,
    getState: () => CreateRoleModalState,
    onReduced: (state: CreateRoleModalState) => void
  ) {
    super.init(reducer, getState, onReduced);
    this.translocoService.langChanges$.subscribe(() => {
      if(this.getState().roleNameError != null){
        this.handleChangeRoleName(this.getState().roleName)
      }
      if(this.getState().roleDescriptionError != null){
        this.handleChangeRoleDescription(this.getState().roleDescription)
      }
    })
  }

  execute(action: CreateRoleModalAction) {
    switch (action.type) {
      case CreateRoleModalActionTypes.CHANGE_NAV_ITEM:
        this.handleChangeNavItem(action.id, action.isCheckCurrent)
        break;
      case CreateRoleModalActionTypes.CHANGE_ROLE_NAME:
        this.handleChangeRoleName(action.value)
        break;
      case CreateRoleModalActionTypes.LOAD_ROLE:
        this.roleService.getRole(action.id).subscribe({
          next: (role) => {
            this.reduce({
              type: CreateRoleModalResultActionTypes.LOAD_ROLE,
              id: action.id,
              name: role.name,
              description: role.description,
              permissions: role.permissions
            });
          },
          error: () => {
            this.toastsService.createToast({
              title: translate(CreateRoleModalConstants.TRANSLOCO_READ + '.get-role-error-title'),
              description: translate(CreateRoleModalConstants.TRANSLOCO_READ + '.get-role-error-description'),
              state: ToastState.ERROR
            })
          }
        })
        break;
      case CreateRoleModalActionTypes.CHANGE_ROLE_DESCRIPTION:
        this.handleChangeRoleDescription(action.value)
        break;
      case CreateRoleModalActionTypes.SAVE_ROLE:
        this.handleSaveRole()
        break;

      case CreateRoleModalActionTypes.INIT:
        this.reduce({
          type: CreateRoleModalResultActionTypes.INIT,
        })
        break;
    }
  }

  private handleChangeRoleName(value: string){
    const roleNameError = this.roleNameValidator.validate(value)
    this.reduce({
      type: CreateRoleModalResultActionTypes.CHANGE_ROLE_NAME,
      value: value,
      error: roleNameError ?
        translate(
          CreateRoleModalConstants.TRANSLOCO_READ + '.' + roleNameError,
          {
            maxLength: EmployeesRolesConstants.ROLE_NAME_MAX_LENGTH
          }) : null,
      isValid: this.getState().roleDescriptionError == null && roleNameError == null
    })
  }

  private handleChangeRoleDescription(value: string){
    const roleErrorDescription = this.roleDescriptionValidator.validate(value)
    this.reduce({
      type: CreateRoleModalResultActionTypes.CHANGE_ROLE_DESCRIPTION,
      value: value,
      error: roleErrorDescription ?
        translate(
          CreateRoleModalConstants.TRANSLOCO_READ + '.' + roleErrorDescription,
          {
            maxLength: EmployeesRolesConstants.ROLE_DESCRIPTION_MAX_LENGTH
          }) : null,
      isValid: this.getState().roleNameError == null && roleErrorDescription == null
    })
  }

  private handleSaveRole(){
    if(this.checkMainValid())
    {
      const createRole = {
        name: this.getState().roleName.trim().split(' ').filter((str) => str.length > 0).join(' '),
        description: this.getState().roleDescription.trim().split(' ').filter((str) => str.length > 0).join(' '),
        permissions: this.getState().permissions
      }
      if(this.getState().loadedRoleId == '')
      {
        this.reduce({
          type: CreateRoleModalResultActionTypes.CHANGE_IS_CREATING,
          value: true
        })
        this.roleService.createRole(createRole).subscribe({
          next: () => {
            this.toastsService.createToast({
              title: translate(CreateRoleModalConstants.TRANSLOCO_READ + '.role-created-title'),
              description: "",
              state: ToastState.SUCCESS
            });
            this.rolesExecutor.execute({
              type: EmployeesRolesActionTypes.UPDATE_ROLES
            });
            this.reduce({
              type: CreateRoleModalResultActionTypes.CHANGE_IS_CREATING,
              value: false
            });
            this.rolesExecutor.execute({
              type: EmployeesRolesActionTypes.CHANGE_CREATE_ROLE_MODAL_VISIBLE,
              value: false
            });
          },
          error: err => {
            this.toastsService.createToast({
              title: translate(CreateRoleModalConstants.TRANSLOCO_READ + '.create-role-error-title'),
              description: translate(CreateRoleModalConstants.TRANSLOCO_READ + '.create-role-error-description'),
              state: ToastState.ERROR
            })
            this.reduce({
              type: CreateRoleModalResultActionTypes.CHANGE_IS_CREATING,
              value: false
            });
          }
        })
      }
      else {
        this.roleService.updateRole(
          this.getState().loadedRoleId,
          createRole
        ).subscribe({
          next: () => {
            this.rolesExecutor.execute({
              type: EmployeesRolesActionTypes.UPDATE_ROLES
            });
            this.rolesExecutor.execute({
              type: EmployeesRolesActionTypes.CHANGE_CREATE_ROLE_MODAL_VISIBLE,
              value: false
            });
          },
          error: () =>{
            this.toastsService.createToast({
              title: translate(CreateRoleModalConstants.TRANSLOCO_READ + '.save-role-error-title'),
              description: translate(CreateRoleModalConstants.TRANSLOCO_READ + '.save-role-error-description'),
              state: ToastState.ERROR
            })
          }
        })
      }
    }
  }

  private handleChangeNavItem(id: string, isCheckCurrent: boolean){
    let result: StepViewState = this.getState().stepViewState
      switch (id){
        case CreateRoleModalNavItems.MAIN:
          result = { ...result,
            selectedNavItem: CreateRoleModalNavItems.MAIN,
            stepNumber: 1,
            clueText: CreateRoleModalClueTexts.MAIN,
            clueTitle: CreateRoleModalClueTitles.MAIN,
          }
          break;
        case CreateRoleModalNavItems.ACCESSES:
          if(!isCheckCurrent || this.checkMainValid()){
            result = { ...result,
              selectedNavItem: CreateRoleModalNavItems.ACCESSES,
              stepNumber: 2,
              clueText: CreateRoleModalClueTexts.ACCESSES,
              clueTitle: CreateRoleModalClueTitles.ACCESSES,
            }
          }
          else{
            result = { ...result,
              isShowErrorMain: true
            }
          }
          break
      }

    this.reduce({
      type: CreateRoleModalResultActionTypes.CHANGE_NAV_ITEM,
      stepState: result
    })
  }

  private checkMainValid(): boolean{
    this.handleChangeRoleName(this.getState().roleName)
    this.handleChangeRoleDescription(this.getState().roleDescription)
    return this.isValid()
  }

  private isValid(): boolean{
    return this.getState().roleNameError == null && this.getState().roleDescriptionError == null
  }
}
