<div
  class="rounded-[16px] w-full border-[1px] relative block bg-white border-tt-border-default"
  #container
>
  <div
    class="absolute left-[24px] top-[24px] rounded-full h-[24px] w-[160px] flex items-center bg-[#F1F1F1]"
  ></div>

  <div
    class="w-full h-[144px] bg-center bg-cover rounded-t-[16px] bg-[#FAFAFA]"
  ></div>

  <div
    class="p-[24px] pb-[30px] w-full h-[calc(100%_-_144px)] flex flex-col"
  >
    <div class="rounded-full w-[90px] h-[20px] bg-[#F1F1F1]"></div>
    <div
      class="rounded-full w-full h-[24px] mt-[8px] bg-[#F1F1F1]"
      *ngFor="let i of [0, 1]"
    ></div>

    <div
      class="mt-[16px] flex flex-col gap-[4px]"
    >
      <div
        class="rounded-full w-full h-[16px] bg-[#F6F6F6]"
        *ngFor="let i of [0, 1, 2, 3]"
      ></div>
    </div>

  </div>
</div>
