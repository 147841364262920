import { Component, EventEmitter, Input, Output } from "@angular/core";
import { numToWordType } from "../../../../../../../../core/utils/num-to-word-type";
import { AgileDeskConstants } from "../../../common/agile-desk-constants";

@Component({
  selector: 'app-tasks-toolbar',
  templateUrl: './tasks-toolbar.component.html',
  styleUrls: ['./tasks-toolbar.component.scss']
})
export class TasksToolbarComponent {
  @Input() selectedCount: number = 0;

  @Output() onUnselect = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  protected readonly AgileDeskConstants = AgileDeskConstants;
  protected readonly numWordType = numToWordType;
}
