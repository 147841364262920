import { PasswordResetState } from "./password-reset-state";
import { PasswordResetAction, PasswordResetActionTypes } from "./password-reset-action";
import { PasswordResetResultAction, PasswordResetResultActionTypes } from "./password-reset-result-action";
import { Inject, Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { Validator } from "../../../../core/validators/validator";
import { Location } from "@angular/common";
import { AuthService } from "../../../authorization/domain/auth.service";
import { ToastsService } from "../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../core/components/toast-alert/toast-alert.component";
import { ActivatedRoute } from "@angular/router";
import { AuthorizationNavigator } from "../../../authorization/presentation/navigation/authorization-navigator";
import { HttpErrorResponse } from "@angular/common/http";
import { translate, TranslocoService } from "@jsverse/transloco";
import { PasswordResetConstants } from "../../common/password-reset-constants";

@Injectable()
export class PasswordResetExecutor extends Executor<
  PasswordResetState,
  PasswordResetAction,
  PasswordResetResultAction
> {

  constructor(
    @Inject('PasswordResetPasswordValidator')
    private passwordValidator: Validator,
    private location: Location,
    private authService: AuthService,
    private toastsService: ToastsService,
    private route: ActivatedRoute,
    private navigator: AuthorizationNavigator,
    private translocoService: TranslocoService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<PasswordResetState, PasswordResetResultAction>,
    getState: () => PasswordResetState,
    onReduced: (state: PasswordResetState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.handleInit()
    this.translocoService.langChanges$.subscribe(()=>
    {
      this.handleChangeCurrentPassword(this.getState().currentPassword)
      this.handleChangeNewPassword(this.getState().newPassword)
      this.handleChangeNewConfirmPassword(this.getState().newConfirmPassword)
    })
  }

  execute(action: PasswordResetAction) {
    switch (action.type) {
      case PasswordResetActionTypes.GO_TO_NEXT_STAGE:
        this.handleGoToNextStage()
        break;
      case PasswordResetActionTypes.CHANGE_CURRENT_PASSWORD:
        this.handleChangeCurrentPassword(action.value)
        break
      case PasswordResetActionTypes.CHANGE_NEW_CONFIRM_PASSWORD:
        this.handleChangeNewConfirmPassword(action.value)
        break
      case PasswordResetActionTypes.CHANGE_NEW_PASSWORD:
        this.handleChangeNewPassword(action.value)
        break
      case PasswordResetActionTypes.GO_BACK:
        if(this.getState().recoveryToken)
        {
          this.navigator.openLogin()
        } else {
          this.location.back()
        }
        break;
    }
  }

  private handleInit(){
    const token = this.route.snapshot.queryParamMap.get("t");
    if(token){
      this.reduce({
        type: PasswordResetResultActionTypes.CHANGE_STAGE,
        newStage: 'loading'
      })
      this.reduce({
        type: PasswordResetResultActionTypes.RECOVERY_INIT,
        token: token
      })
      this.authService.startPasswordRestore(token).subscribe({
        next: resp => {
          this.reduce({
            type: PasswordResetResultActionTypes.RECOVERY_INIT,
            token: token,
            salt: resp.salt,
          })
          this.reduce({
            type: PasswordResetResultActionTypes.CHANGE_STAGE,
            newStage: 'new-password'
          })
        },
        error: err => {
          if(err instanceof HttpErrorResponse)
          {
            switch (err.status) {
              case 409:
                this.reduce({
                  type: PasswordResetResultActionTypes.CHANGE_STAGE,
                  newStage: 'already-changed'
                })
                break
              case 410:
                this.reduce({
                  type: PasswordResetResultActionTypes.CHANGE_STAGE,
                  newStage: 'session-expired'
                })
                break
              default:
                this.reduce({
                  type: PasswordResetResultActionTypes.CHANGE_STAGE,
                  newStage: 'error'
                })
                break
            }

          } else {
            this.reduce({
              type: PasswordResetResultActionTypes.CHANGE_STAGE,
              newStage: 'error'
            })
          }
        }
      })
    }
  }

  private handleChangeCurrentPassword(value: string){
    const error = this.passwordValidator.validate(value)
    this.reduce({
      type: PasswordResetResultActionTypes.CHANGE_CURRENT_PASSWORD,
      value: value,
      error: error != null ? translate(PasswordResetConstants.TRANSCLOCO_READ + '.' + error) : null
    })
    this.handleChangeNewConfirmPassword(this.getState().newConfirmPassword)
  }

  private handleChangeNewConfirmPassword(value: string){
    this.reduce({
      type: PasswordResetResultActionTypes.CHANGE_NEW_CONFIRM_PASSWORD,
      value: value,
      error: value == this.getState().newPassword ? null : translate(PasswordResetConstants.TRANSCLOCO_READ + '.password-error-not-match')
    })
  }

  private handleChangeNewPassword(value: string){
    this.reduce({
      type: PasswordResetResultActionTypes.CHANGE_NEW_PASSWORD,
      value: value,
      error: value.length > 7 ?
        (this.passwordValidator.validate(value) ??
          value === this.getState().currentPassword ?
          translate(PasswordResetConstants.TRANSCLOCO_READ + '.password-error-differ-prev') : null)
        : translate(
          PasswordResetConstants.TRANSCLOCO_READ + '.password-error-min-length',
          {
            minLength: PasswordResetConstants.MIN_PASSWORD_LENGTH
          })
    })
  }

  private handleGoToNextStage(){
    switch (this.getState().currentStage){
      case "init":
        this.goToNextStageFromInit()
        break
      case "new-password":
        this.goToNextStageFromNewPassword()
        break
    }
  }

  private goToNextStageFromNewPassword(){
    this.handleChangeNewPassword(this.getState().newPassword)
    this.handleChangeNewConfirmPassword(this.getState().newConfirmPassword)
    if(
      this.getState().newPasswordError === null && this.getState().newConfirmPasswordError === null
    ) {
      const state = this.getState()
      this.reduce({
        type: PasswordResetResultActionTypes.CHANGE_IS_LOADING,
        value: true
      })

      if(state.recoveryToken && state.recoverySalt)
      {
        this.authService.finishPasswordRestore(
          state.recoveryToken,
          state.newPassword,
          state.recoverySalt
        ).subscribe({
          next: value => {
            this.reduce({
              type: PasswordResetResultActionTypes.CHANGE_IS_LOADING,
              value: false
            })
            this.reduce({
              type: PasswordResetResultActionTypes.CHANGE_STAGE,
              newStage: 'success'
            })
          },
          error: err => {
            this.reduce({
              type: PasswordResetResultActionTypes.CHANGE_IS_LOADING,
              value: false
            })
            this.reduce({
              type: PasswordResetResultActionTypes.CHANGE_STAGE,
              newStage: 'error'
            })
          }
        })
      }
      else {
        const salt = localStorage.getItem('salt') ?? ''
        this.authService.changePassword(
          this.getState().currentPassword,
          this.getState().newPassword,
          salt
        ).subscribe({
          next: value => {
            this.reduce({
              type: PasswordResetResultActionTypes.CHANGE_STAGE,
              newStage: 'success'
            })
            this.reduce({
              type: PasswordResetResultActionTypes.CHANGE_IS_LOADING,
              value: false
            })
          },
          error: err => {
            this.reduce({
              type: PasswordResetResultActionTypes.CHANGE_IS_LOADING,
              value: false
            })
            this.toastsService.createToast({
              title: translate(PasswordResetConstants.TRANSCLOCO_READ + '.change-password-error-title'),
              description: translate(PasswordResetConstants.TRANSCLOCO_READ + '.change-password-error-description'),
              state: ToastState.ERROR
            })
          }
        })
      }
    }
  }

  private goToNextStageFromInit(){
    const currentPassword = this.getState().currentPassword
    this.reduce({
      type: PasswordResetResultActionTypes.CHANGE_CURRENT_PASSWORD,
      value: currentPassword,
      error: this.passwordValidator.validate(currentPassword)
    })
    if(
      this.getState().currentPasswordError === null
    ) {
      this.reduce({
        type: PasswordResetResultActionTypes.CHANGE_IS_LOADING,
        value: true
      })

      const salt = localStorage.getItem('salt') ?? ''
      this.authService.checkPassword(currentPassword, salt).subscribe({
        next: resp => {
          if (resp.valid) {
            this.reduce({
              type: PasswordResetResultActionTypes.CHANGE_STAGE,
              newStage: "new-password"
            });
          }
          else {
            this.toastsService.createToast({
              title: translate(PasswordResetConstants.TRANSCLOCO_READ + '.invalid-password-error-title'),
              description: translate(PasswordResetConstants.TRANSCLOCO_READ + '.invalid-password-error-title'),
              state: ToastState.ERROR
            })
          }
          this.reduce({
            type: PasswordResetResultActionTypes.CHANGE_IS_LOADING,
            value: false
          });
        },
        error: err => {
          this.toastsService.createToast({
            title: translate(PasswordResetConstants.TRANSCLOCO_READ + '.check-password-error-title'),
            description: translate(PasswordResetConstants.TRANSCLOCO_READ + '.check-password-error-description'),
            state: ToastState.ERROR
          })
          this.reduce({
            type: PasswordResetResultActionTypes.CHANGE_IS_LOADING,
            value: false
          });
        }
      })
    }
  }
}
