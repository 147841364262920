import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NewsEditorActionTypes } from "../../state/news-editor-action";
import { NewsEditorConstants } from "../../../common/news-editor-constants";
import { NewsSummaryEntity } from "../../../../../domain/news-summary-entity";

@Component({
  selector: 'app-news-publish-settings',
  templateUrl: './publish-settings.component.html',
  styleUrls: ['./publish-settings.component.scss']
})
export class PublishSettingsComponent {
  @Input({required: true}) news!: NewsSummaryEntity
  @Input() titleError: string | null = null
  @Input() descriptionError: string | null = null

  @Output() onChangeTitle = new EventEmitter<string>()
  @Output() onChangeDescription = new EventEmitter<string>()
  @Output() onChangeImageId = new EventEmitter<string>()
  @Output() onCloseSidebar = new EventEmitter()
  @Output() onPublish = new EventEmitter()

  protected readonly NewsEditorActionTypes = NewsEditorActionTypes;
  protected readonly NewsEditorConstants = NewsEditorConstants;
}
