import { Component, ElementRef, Input, Renderer2, ViewChild } from "@angular/core";
import { NewsSummaryEntity } from "../../../../../domain/news-summary-entity";
import { NewsEditorConstants } from "../../../common/news-editor-constants";
import { ListType } from "../../../../../domain/list-type";
import { changeNewsListByType } from "../../../../../utils/change-news-list-by-type";

const PADDING_X = 60
const MAX_SCALING = 0.8

const RESIZE_COLUMN_SIZES = {
  three: 1336,
  two: 978,
}

@Component({
  selector: 'app-news-preview',
  templateUrl: './news-preview.component.html',
  styleUrls: ['./news-preview.component.scss']
})
export class NewsPreviewComponent {
  @Input({ required: true }) news!: NewsSummaryEntity
  @ViewChild('newsList') newsList?: ElementRef
  @ViewChild('previewBlock') previewBlock?: ElementRef
  @ViewChild('toolbar', {read: ElementRef}) toolbar?: ElementRef

  listType: ListType = "grid-cell"

  private resizeObserver = new ResizeObserver(
    (entries) => {
      entries.forEach(entry => {
        this.resizeNewsListPreview(entry.contentRect.width)
      })
    })

  protected readonly NewsEditorConstants = NewsEditorConstants;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) {
    this.resizeObserver.observe(this.el.nativeElement)
  }

  resizeNewsListPreview(width: number){
    if(!this.newsList && !this.previewBlock && !this.toolbar){
      return
    }

    const currentWidth = width - PADDING_X * 2
    let currentScale = width / width + PADDING_X * 2
    if(currentWidth >= RESIZE_COLUMN_SIZES.three * MAX_SCALING){
      this.listType = "grid-cell"
      currentScale = currentWidth / RESIZE_COLUMN_SIZES.three
      this.renderer.setStyle(this.previewBlock!.nativeElement, 'width', `${RESIZE_COLUMN_SIZES.three}px`)
      this.renderer.setStyle(this.previewBlock!.nativeElement, 'left', `${(width - RESIZE_COLUMN_SIZES.three * (currentScale < 1 ? currentScale : 1)) / 2}px`)
    } else if(currentWidth >= RESIZE_COLUMN_SIZES.two * MAX_SCALING){
      this.listType = "grid-cell-full"
      currentScale = currentWidth / RESIZE_COLUMN_SIZES.two
      this.renderer.setStyle(this.previewBlock!.nativeElement, 'width', `${RESIZE_COLUMN_SIZES.two}px`)
      this.renderer.setStyle(this.previewBlock!.nativeElement, 'left', `${(width - RESIZE_COLUMN_SIZES.two * (currentScale < 1 ? currentScale : 1)) / 2}px`)
    } else {
      this.listType = "full"
      this.renderer.setStyle(this.previewBlock!.nativeElement, 'width', `${currentWidth}px`)
      this.renderer.setStyle(this.previewBlock!.nativeElement, 'left', `${PADDING_X}px`)
    }

    if(currentScale < 1)
    {
      this.renderer.setStyle(this.previewBlock!.nativeElement, 'scale', `${currentScale}`)
    } else {
      this.renderer.removeStyle(this.previewBlock!.nativeElement, 'scale')
    }

    changeNewsListByType(this.listType, this.renderer, this.newsList!.nativeElement)
  }
}
