import { ActionElementStyle, ActionSection } from "../../../core/components/action-menu/action-menu.component";

export class NewsConstants{
  public static NEWS: string = "Новости";

  public static readonly FULL_NEWS_ACTION_MENU_SECTIONS: ActionSection[] = [{
    elements: [
      {
        id: 'edit',
        name: 'edit'
      },
      {
        id: 'delete',
        name: 'delete',
        type: ActionElementStyle.DANGER
      },
    ]
  }]
}
