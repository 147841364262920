import { ListType } from "../../../../domain/list-type";
import { NewsSummaryEntity } from "../../../../domain/news-summary-entity";

export type NewsListAction =
  | ChangeListTypeAction
  | CreateNewsAction
  | CloseDraftModalAction
  | ContinueDraftModalAction
  | CreateNewDraftModalAction
  | OpenFullNewsModalAction
  | CloseFullNewsModalAction
  | DeleteNewsAction
  | ChangeCategoryAction
  | ChangeSearchFieldAction

export enum NewsListActionTypes {
  CHANGE_LIST_TYPE,
  CREATE_NEWS,
  CLOSE_DRAFT_MODAL,
  CONTINUE_DRAFT,
  CREATE_NEW_DRAFT,
  OPEN_FULL_NEWS,
  CLOSE_FULL_NEWS,
  DELETE_NEWS,
  CHANGE_CATEGORY,
  CHANGE_SEARCH_FIELD,
}

export interface DeleteNewsAction {
  readonly type: NewsListActionTypes.DELETE_NEWS,
  readonly id: string
}

export interface ChangeSearchFieldAction {
  readonly type: NewsListActionTypes.CHANGE_SEARCH_FIELD,
  readonly value: string
}

export interface ChangeCategoryAction {
  readonly type: NewsListActionTypes.CHANGE_CATEGORY,
  readonly id: string
}

export interface CloseDraftModalAction {
  readonly type: NewsListActionTypes.CLOSE_DRAFT_MODAL,
}

export interface ContinueDraftModalAction {
  readonly type: NewsListActionTypes.CONTINUE_DRAFT,
}

export interface CreateNewDraftModalAction {
  readonly type: NewsListActionTypes.CREATE_NEW_DRAFT,
}

export interface OpenFullNewsModalAction {
  readonly type: NewsListActionTypes.OPEN_FULL_NEWS,
  readonly news: NewsSummaryEntity
}

export interface CloseFullNewsModalAction {
  readonly type: NewsListActionTypes.CLOSE_FULL_NEWS,
}

export interface ChangeListTypeAction {
  readonly type: NewsListActionTypes.CHANGE_LIST_TYPE,
  readonly listType: ListType
}

export interface CreateNewsAction {
  readonly type: NewsListActionTypes.CREATE_NEWS,
}
