import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { numToWordType } from "src/app/core/utils/num-to-word-type";
import { stringToRgb } from "src/app/core/utils/string-to-rgb";
import { HighlightedPartsStr } from "../text-with-highlighting/text-with-highlighting.component";
import { parseToHighlightedParts } from "../../utils/parse-to-highlighted-parts";
import { SelectUserModalService } from "../../services/select-user-modal-service/select-user-modal.service";
import { UserSummeryComponent } from "../user-summery/user-summery.component";
import { LoadingShadingComponent } from "../loading-shading/loading-shading.component";
import {
  AlertModalUnscrollableBoxComponent
} from "../alert-modal/alert-modal-unscrolable-box/alert-modal-unscrollable-box.component";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { NgForOf, NgIf } from "@angular/common";
import { ImageStubComponent } from "../image-stub/image-stub.component";
import { FieldsModule } from "../fields/fields.module";
import { provideTranslocoScope, translate, TranslocoDirective } from "@jsverse/transloco";
import { ToastsService } from "../toast-alert/services/toast-alert.service";
import { ToastState } from "../toast-alert/toast-alert.component";

const TRANSLOCO_READ = 'selectUserModal';

@Component({
  selector: "app-select-user-modal",
  templateUrl: "./select-user-modal.component.html",
  standalone: true,
  imports: [
    UserSummeryComponent,
    LoadingShadingComponent,
    AlertModalUnscrollableBoxComponent,
    AlertModalComponent,
    NgIf,
    ImageStubComponent,
    NgForOf,
    FieldsModule,
    TranslocoDirective
  ],
  styleUrls: ["./select-user-modal.component.scss"],
  providers: [
    provideTranslocoScope({ scope: "core/select-user-modal", alias: TRANSLOCO_READ }),
  ]
})
export class SelectUserModalComponent implements OnChanges {
  @Input() alreadySelectedUserIds: number[] = []
  @Input() isLoading: boolean = false

  @Output() onClose = new EventEmitter();
  @Output() onAddUsers = new EventEmitter<ShortEmployee[]>();

  @ViewChild('employeeList') employeeListEl!: ElementRef;
  elementListParent: HTMLElement | null = null;

  users: ShortEmployee[] = []

  isListLoading: boolean

  filteredEmployees: ShortEmployee[] = this.users
  searchValue: string = '';
  selectedUsers: ShortEmployee[] = []

  employeeListElWindowHeightPercent: number = -1

  protected readonly TRANSLOCO_READ = TRANSLOCO_READ;
  protected readonly stringToRgb = stringToRgb;

  constructor(
    private selectUserModalService: SelectUserModalService,
    private renderer: Renderer2,
    private toastsService: ToastsService
  ) {
    this.isListLoading = true
    selectUserModalService.getUsers().subscribe({
      next: value => {
        this.users = value.filter(user => !this.alreadySelectedUserIds.find(id => id === user.id));
        this.isListLoading = false
        this.onFilter('')
        setTimeout(() => {
          const el = this.employeeListEl.nativeElement as HTMLElement;
          this.elementListParent = el.parentElement;
          if(this.elementListParent)
          {
            this.employeeListElWindowHeightPercent = this.elementListParent.offsetHeight / window.innerHeight
            this.renderer.removeClass(this.elementListParent, 'flex-grow')
            this.onWindowResize()
          }
        })
      },
      error: err => {
        this.onClose.emit()
        toastsService.createToast({
          title: translate(TRANSLOCO_READ + '.get-users-error-title'),
          description: translate(TRANSLOCO_READ + '.get-users-error-description'),
          state: ToastState.ERROR
        })
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if(this.elementListParent) {
      this.renderer.setStyle(
        this.elementListParent,
        'height',
        window.innerHeight * this.employeeListElWindowHeightPercent + 'px'
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onFilter('')
  }

  onSelect(user: ShortEmployee){
    const findUserIndex = this.selectedUsers.findIndex(selected => user == selected)
    if(findUserIndex != -1){
      user.isSelected = false
      this.selectedUsers.splice(findUserIndex, 1)
    }else{
      user.isSelected = true
      this.selectedUsers.push(user)
    }
  }

  onFilter(value: string){
    this.searchValue = value
    this.filteredEmployees = this.users.filter(employee => {
      const fullName = employee.surname + ' ' + employee.name + (employee.patronymic ? ' ' + employee.patronymic : '')
      if(fullName.toLowerCase().includes(value.toLowerCase())
        || employee.email.toLowerCase().includes(value.toLowerCase())
      ){
        employee.nameParts = parseToHighlightedParts(fullName, value)
        employee.emailParts = parseToHighlightedParts(employee.email, value)
        return true
      }
      return false
    })
  }

  addUsers(){
    this.onAddUsers.emit(this.selectedUsers.slice())
  }

  protected readonly numToWordType = numToWordType;
}

export interface ShortEmployee {
  id: number,
  surname: string,
  name: string,
  patronymic?: string
  email: string
  isSelected: boolean
  nameParts: HighlightedPartsStr[]
  emailParts: HighlightedPartsStr[]
}
