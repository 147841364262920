import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsEditorComponent } from "./presentation/news-editor.component";
import { SvgArrowBack, SvgXMark } from "../../../../core/components/svg-components/svg.components";
import { InputNewsImageComponent } from "./presentation/components/input-news-image/input-news-image.component";
import { NewsListModule } from "../news-list/news-list.module";
import { EditorChangesService } from "../../../../core/components/yoopta-editor/data/editor-changes-service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { Validator } from "../../../../core/validators/validator";
import { EmptyRule, MaxLengthRule } from "../../../../core/validators/rule";
import { AlertModalComponent } from "../../../../core/components/alert-modal/alert-modal.component";
import { LoadingShadingComponent } from "../../../../core/components/loading-shading/loading-shading.component";
import { StopwatchComponent } from "../../../../core/components/stopwatch/stopwatch.component";
import { ButtonComponent } from "../../../../core/components/buttons/button/button.component";
import { PaymentAndTariffModule } from "../../../settings/modules/payment-and-tariff/payment-and-tariff.module";
import { PageShadingComponent } from "../../../../core/components/page-shading/page-shading.component";
import { FieldsModule } from "../../../../core/components/fields/fields.module";
import { YooptaEditorModule } from "../../../../core/components/yoopta-editor/yoopta-editor.module";
import { provideTranslocoScope, TranslocoModule } from "@jsverse/transloco";
import { YooptaEditorConstants } from "../../../../core/components/yoopta-editor/common/yoopta-editor-constants";
import { ColumnResizeDirective } from "../../../../core/directives/resize-x.directive";
import { CategorySkeletonComponent } from './presentation/components/category-skeleton/category-skeleton.component';
import { SvgNewsEditorPreviewToolbarComponent } from "./presentation/icons/news-editor-icons";
import { PublishSettingsComponent } from './presentation/components/publish-settings/publish-settings.component';
import { NewsPreviewComponent } from './presentation/components/news-preview/news-preview.component';
import { NewsItemSkeletonComponent } from './presentation/components/news-item-skeleton/news-item-skeleton.component';

@NgModule({
  declarations: [
    NewsEditorComponent,
    InputNewsImageComponent,
    CategorySkeletonComponent,
    SvgNewsEditorPreviewToolbarComponent,
    PublishSettingsComponent,
    NewsPreviewComponent,
    NewsItemSkeletonComponent
  ],
  imports: [
    CommonModule,
    SvgArrowBack,
    SvgXMark,
    NewsListModule,
    MatProgressSpinnerModule,
    AlertModalComponent,
    LoadingShadingComponent,
    StopwatchComponent,
    ButtonComponent,
    PaymentAndTariffModule,
    PageShadingComponent,
    YooptaEditorModule,
    FieldsModule,
    TranslocoModule,
    ColumnResizeDirective
  ],
  providers: [
    EditorChangesService,
    provideTranslocoScope({ scope: "core/editor", alias: YooptaEditorConstants.TRANSLOCO_READ }),
    {
      provide: 'NewsTitleValidator',
      useExisting: Validator,
      useFactory: NewsEditorModule.titleValidatorFactory,
    },
    {
      provide: 'NewsDescriptionValidator',
      useExisting: Validator,
      useFactory: NewsEditorModule.descriptionValidatorFactory,
    },
  ]
})
export class NewsEditorModule {
  public static titleValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите заголвок'),
      new MaxLengthRule('Заголовок не может быть больше 128 символов', 128),
    ]);

  public static descriptionValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите описание'),
      new MaxLengthRule('Описание не может быть больше 180 символов', 180),
    ]);
}
