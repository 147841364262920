import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy, OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { PermissionsDto } from "../../../../../../../core/services/role-service/dto/create-role-dto";
import { RoleConstants } from "../../../common/role-constants";

@Component({
  selector: "app-role-permissions",
  templateUrl: "./role-permissions.component.html",
  styleUrls: ["./role-permissions.component.scss"]
})
export class RolePermissionsComponent implements OnChanges, OnInit {
  @Output() onSave = new EventEmitter<PermissionsDto>();

  @Input() isReadOnly: boolean = true;
  @Input() permissions: PermissionsDto = {
    knowledgeBase: {
      viewKnowledgeBase: true,
      createKnowledgeBase: false,
      editKnowledgeBase: false,
      deleteKnowledgeBase: false
    },
    company: {
      viewEmployee: true,
      createEmployee: false,
      editEmployee: false,
      deleteEmployee: false
    },
    settings: {
      billing: false,
      main: false,
    },
    role: {
      viewRole: true,
      createRole: false,
      editRole: false,
      deleteRole: false
    },
    news: {
      viewNews: true,
      createNews: false,
      editNews: false,
      deleteNews: false
    },
    boards: {
      viewBoards: true,
      createBoards: false,
      editBoards: false,
      deleteBoards: false
    }
  }

  initPermissions: PermissionsDto = this.permissions;

  ngOnInit(): void {
    this.decline()
  }

  isInitEqual(): boolean{
    return this.initPermissions.settings.main == this.permissions.settings.main
      && this.initPermissions.settings.billing == this.permissions.settings.billing
      && this.initPermissions.knowledgeBase.viewKnowledgeBase == this.permissions.knowledgeBase.viewKnowledgeBase
      && this.initPermissions.knowledgeBase.editKnowledgeBase == this.permissions.knowledgeBase.editKnowledgeBase
      && this.initPermissions.knowledgeBase.deleteKnowledgeBase == this.permissions.knowledgeBase.deleteKnowledgeBase
      && this.initPermissions.knowledgeBase.createKnowledgeBase == this.permissions.knowledgeBase.createKnowledgeBase
      && this.initPermissions.role.createRole == this.permissions.role.createRole
      && this.initPermissions.role.editRole == this.permissions.role.editRole
      && this.initPermissions.role.viewRole == this.permissions.role.viewRole
      && this.initPermissions.role.deleteRole == this.permissions.role.deleteRole
      && this.initPermissions.company.viewEmployee == this.permissions.company.viewEmployee
      && this.initPermissions.company.editEmployee == this.permissions.company.editEmployee
      && this.initPermissions.company.deleteEmployee == this.permissions.company.deleteEmployee
      && this.initPermissions.company.createEmployee == this.permissions.company.createEmployee
      && this.initPermissions.news.viewNews == this.permissions.news.viewNews
      && this.initPermissions.news.editNews == this.permissions.news.editNews
      && this.initPermissions.news.deleteNews == this.permissions.news.deleteNews
      && this.initPermissions.news.createNews == this.permissions.news.createNews
      && this.initPermissions.boards.viewBoards == this.permissions.boards.viewBoards
      && this.initPermissions.boards.editBoards == this.permissions.boards.editBoards
      && this.initPermissions.boards.deleteBoards == this.permissions.boards.deleteBoards
      && this.initPermissions.boards.createBoards == this.permissions.boards.createBoards
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['permissions']){
      this.initPermissions = {
        knowledgeBase: {
          viewKnowledgeBase: this.permissions.knowledgeBase.viewKnowledgeBase,
          createKnowledgeBase: this.permissions.knowledgeBase.createKnowledgeBase,
          editKnowledgeBase: this.permissions.knowledgeBase.editKnowledgeBase,
          deleteKnowledgeBase: this.permissions.knowledgeBase.deleteKnowledgeBase
        },
        company: {
          viewEmployee: this.permissions.company.viewEmployee,
          editEmployee: this.permissions.company.editEmployee,
          createEmployee: this.permissions.company.createEmployee,
          deleteEmployee: this.permissions.company.deleteEmployee
        },
        settings: {
          billing: this.permissions.settings.billing,
          main: this.permissions.settings.main
        },
        role: {
          viewRole: this.permissions.role.viewRole,
          createRole: this.permissions.role.createRole,
          editRole: this.permissions.role.editRole,
          deleteRole: this.permissions.role.deleteRole
        },
        news: {
          viewNews: true,
          createNews: false,
          editNews: false,
          deleteNews: false
        },
        boards: {
          viewBoards: true,
          createBoards: false,
          editBoards: false,
          deleteBoards: false
        }
      }
    }
  }

  save(){
    this.onSave.emit(this.permissions)
  }

  decline(){
    this.permissions = {
      knowledgeBase: {
        viewKnowledgeBase: this.initPermissions.knowledgeBase.viewKnowledgeBase,
        createKnowledgeBase: this.initPermissions.knowledgeBase.createKnowledgeBase,
        editKnowledgeBase: this.initPermissions.knowledgeBase.editKnowledgeBase,
        deleteKnowledgeBase: this.initPermissions.knowledgeBase.deleteKnowledgeBase
      },
      company: {
        viewEmployee: this.initPermissions.company.viewEmployee,
        editEmployee: this.initPermissions.company.editEmployee,
        createEmployee: this.initPermissions.company.createEmployee,
        deleteEmployee: this.initPermissions.company.deleteEmployee
      },
      settings: {
        billing: this.initPermissions.settings.billing,
        main: this.initPermissions.settings.main
      },
      role: {
        viewRole: this.initPermissions.role.viewRole,
        createRole: this.initPermissions.role.createRole,
        editRole: this.initPermissions.role.editRole,
        deleteRole: this.initPermissions.role.deleteRole
      },
      news: {
        viewNews: true,
        createNews: false,
        editNews: false,
        deleteNews: false
      },
      boards: {
        viewBoards: true,
        createBoards: false,
        editBoards: false,
        deleteBoards: false
      }
    }
  }

  protected readonly RoleConstants = RoleConstants;
}
