<div
  class="w-full h-full flex flex-col gap-[8px]"
  *transloco="let t; read: NewsEditorConstants.TRANSLOCO_READ"
>
  <p class="text-tt-input-field-label text-tt-font-size_m font-normal">
    {{ t('image-label') }}
  </p>

  <div
    class="rounded-[16px] overflow-hidden w-[309px] h-[174px] relative group"
    [style.background-image]="'url(assets/image-input-placeholder.png)'"
  >
    <div
      class="w-full h-full justify-center items-center empty-image-color"
      [ngClass]="{
        'absolute left-0 top-0 opacity-80 z-10 hidden group-hover:flex': imageUrl != '',
        'flex': imageUrl == ''
      }"
    >
      <button
        class="h-[40px] rounded-[8px] border-[1px] border-tt-text-contrast px-[22px] flex items-center"
        (click)="selectClicked()"
      >
        <p class="text-[14px] leading-[17px] font-medium text-tt-text-contrast">
          <span *ngIf="imageUrl == ''">{{ t('image-placeholder') }}</span>
          <span *ngIf="imageUrl != ''">{{ t('image-change-placeholder') }}</span>
        </p>
      </button>
    </div>

    <img
      *ngIf="imageUrl != ''"
      src="{{imageUrl}}"
      class="w-full h-full object-cover"
      alt=""
    />
  </div>

</div>

<input type="file" accept="image/*" class="hidden" #inputImage (change)="onSelectFile($event)">
