import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsListComponent } from './presentation/news-list.component';
import { SortPopupComponent } from './presentation/components/sort-popup/sort-popup.component';
import {
  SvgArrow,
  SvgArrowBack,
  SvgHeaderArrow, SvgLink, SvgTripleDot,
  SvgXMark
} from "../../../../core/components/svg-components/svg.components";
import { NewsItemComponent } from './presentation/components/news-item/news-item.component';
import { RouterLink } from "@angular/router";
import { GridListToggleButtonComponent } from './presentation/components/grid-list-toggle-button/grid-list-toggle-button.component';
import { FullNewsComponent } from "./presentation/components/full-news/full-news.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { LoadingShadingComponent } from "../../../../core/components/loading-shading/loading-shading.component";
import { AlertModalComponent } from "../../../../core/components/alert-modal/alert-modal.component";
import { ButtonComponent } from "../../../../core/components/buttons/button/button.component";
import { PageHeaderComponent } from "../../../../core/components/page-header/page-header.component";
import { ActionMenu } from "../../../../core/components/action-menu/action-menu.component";
import { PaymentAndTariffModule } from "../../../settings/modules/payment-and-tariff/payment-and-tariff.module";
import { PageShadingComponent } from "../../../../core/components/page-shading/page-shading.component";
import { PopupDirective } from "../../../../core/directives/popup.directive";
import { SvgChecklist, SvgGrid2x2 } from "./presentation/icons/news-list-icons";
import { YooptaEditorModule } from "../../../../core/components/yoopta-editor/yoopta-editor.module";
import { FieldsModule } from "../../../../core/components/fields/fields.module";
import { TranslocoDirective } from "@jsverse/transloco";
import { TextParserComponent } from "../../../../core/components/text-parser/text-parser.component";
import { CategoriesComponent } from './presentation/components/categories/categories.component';
import { AvatarComponent } from "../../../../core/components/avatar/avatar.component";
import { TooltipDirective } from "../../../../core/directives/tooltip.directive";
import {
  TextWithHighlightingComponent
} from "../../../../core/components/text-with-highlighting/text-with-highlighting.component";
import { ImageStubComponent } from "../../../../core/components/image-stub/image-stub.component";

@NgModule({
  declarations: [
    NewsListComponent,
    SortPopupComponent,
    NewsItemComponent,
    GridListToggleButtonComponent,
    FullNewsComponent,
    SvgGrid2x2,
    SvgChecklist,
    CategoriesComponent,
  ],
  imports: [
    CommonModule,
    SvgHeaderArrow,
    RouterLink,
    SvgXMark,
    SvgArrowBack,
    SvgLink,
    SvgTripleDot,
    MatTooltipModule,
    MatProgressSpinnerModule,
    LoadingShadingComponent,
    AlertModalComponent,
    ButtonComponent,
    PageHeaderComponent,
    YooptaEditorModule,
    ActionMenu,
    PaymentAndTariffModule,
    PageShadingComponent,
    PopupDirective,
    FieldsModule,
    TranslocoDirective,
    TextParserComponent,
    SvgArrow,
    AvatarComponent,
    TooltipDirective,
    TextWithHighlightingComponent,
    ImageStubComponent
  ],
  exports: [
    NewsItemComponent,
    GridListToggleButtonComponent,
  ]
})
export class NewsListModule { }
