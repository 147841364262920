<div
  class="child-routes-size px-[24px] tiny-scrollbar"
  (scroll)="onScroll()"
  #scrollable
  *transloco="let t; read: NewsListConstants.TRANSLOCO_READ"
>
  <div class="w-full flex justify-between relative">
    <app-core-page-header
      [title]="NewsConstants.NEWS"
      [navTabs]="NAV_TABS"
      navTabType="link"
      [borderBottom]="false"
      [tabsTransclocoRead]="NewsListConstants.TRANSLOCO_READ"
    />

    <div
      class="absolute right-[48px] h-full flex items-center"
    >
      <app-core-button
        [tooltip]=""
        (onClick)="performAction({
          type: NewsListActionTypes.CREATE_NEWS
        })"
      >
        {{ t('create-news') }}
      </app-core-button>
    </div>

    <div class="absolute bottom-0 h-[1px] w-full bg-tt-border-default"></div>
  </div>

  <div class="w-full flex justify-center">
    <div
      class="w-full my-[44px] max-w-[1256px] flex flex-col gap-[24px]"
    >
      <app-core-search-field
        class="w-full"
        size="xl"
        className="h-[48]"
        [placeholder]="t('search-placeholder')"
        (onChange)="performAction({
          type: NewsListActionTypes.CHANGE_SEARCH_FIELD,
          value: $event
        })"
      />

      <app-news-categories
        [selected]="state.selectedCategory"
        [categories]="state.categories"
        (onSelect)="performAction({
          type: NewsListActionTypes.CHANGE_CATEGORY,
          id: $event
        })"
      />

      <div
        class="w-full grid flex-wrap"
        #newsList
      >
        <app-news-item
          *ngFor="let newsItem of state.filteredNews"
          [news]="newsItem"
          [type]="state.listType"
          (onClick)="performAction({
            type: NewsListActionTypes.OPEN_FULL_NEWS,
            news: $event
          })"
          #newsListItem
        />
      </div>

      <app-core-image-stub
        *ngIf="state.filteredNews.length === 0"
        [title]="state.news.length === 0 ? t('list-empty-title'): t('no-matches-title')"
        [description]="state.news.length === 0 ? '': t('no-matches-description')"
      />
    </div>

  </div>
</div>

<ng-container
  *transloco="let t; read: NewsListConstants.TRANSLOCO_READ"
>
  <app-core-alert-modal
    *ngIf="state.openDraftModalVisibility && state.draftDto"
    type="info"
    closeActionType="self"
    [title]="t('continue-creating')"
    [cancelButtonText]="t('create-new')"
    [isLoading]="state.openDraftModalIsLoading"
    [confirmButtonText]="t('continue')"
    (onCloseClicked)="performAction({
      type: NewsListActionTypes.CLOSE_DRAFT_MODAL
    })"
    (onConfirmClicked)="performAction({
      type: NewsListActionTypes.CONTINUE_DRAFT
    })"
    (onCancelClicked)="performAction({
      type: NewsListActionTypes.CREATE_NEW_DRAFT
    })"
  >
    <p
      [textParse]="t('created-before', { newsName: state.draftDto.draft.title == '' ? t('without-name') : state.draftDto.draft.title })"
      [parseElements]="[{
        text: state.draftDto.draft.title == '' ? t('without-name') : state.draftDto.draft.title,
        className: 'font-medium',
        type: 'text'
      }]"
    ></p>
  </app-core-alert-modal>

  <app-news-full-news
    *ngIf="state.isFullNewsOpen && state.fullNews"
    [news]="state.fullNews"
    (onClose)="performAction({
    type: NewsListActionTypes.CLOSE_FULL_NEWS
  })"
    (onDelete)="performAction({
    type: NewsListActionTypes.DELETE_NEWS,
    id: state.fullNews.id
  })"
  />

  <div
    class="fixed left-0 top-0 w-screen h-screen z-50"
    *ngIf="state.isPageLoading"
  >
    <app-core-loading-shading/>
  </div>

</ng-container>
