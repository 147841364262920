import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NewsService } from "../../../../../data/news-service";
import { ToastsService } from "../../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../../core/components/toast-alert/toast-alert.component";
import { NewsEditorConstants } from "../../../common/news-editor-constants";

@Component({
  selector: "app-input-news-image",
  templateUrl: "./input-news-image.component.html",
  styleUrls: ["./input-news-image.component.scss"]
})
export class InputNewsImageComponent implements OnChanges {
  @Input() imageId?: string;
  @Input() newsId: string = "";
  @Output() imageUpload = new EventEmitter<string>();

  @ViewChild("inputImage") inputImageRef!: ElementRef;

  imageUrl: string = "";

  constructor(
    private newsService: NewsService,
    private toastsService: ToastsService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageId'] && this.imageId) {
      this.newsService.downloadImage(this.imageId).subscribe({
        next: (result) => {
          this.imageUrl = URL.createObjectURL(result);
        },
      });
    }
  }

  selectClicked(){
    this.inputImageRef.nativeElement.click()
  }

  onSelectFile(event: any){
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      if (event.target && event.target.result) {
        let formData = new FormData();
        formData.append('file', file, file.name);
        this.newsService.uploadImage(this.newsId, formData)
          .subscribe({
            next: (image) => {
              this.imageUpload.emit(image.id)
              this.imageUrl = URL.createObjectURL(new Blob([file], { type: file.type }))
            },
            error: err => {
              this.toastsService.createToast({
                title: 'Не удалось загрузить изображение',
                description: '',
                state: ToastState.ERROR
              });
            }
          })
      }
    }
  }

  protected readonly NewsEditorConstants = NewsEditorConstants;
}
