import { Executor } from "src/app/core/mvi/store";
import { EmployeesListAction, EmployeesListActionTypes } from "./employee-list-action";
import { EmployeesListResultAction, EmployeesListResultActionTypes } from "./employees-list-result-action";
import { Injectable } from "@angular/core";
import { EmployeeSummeryEntity } from "../../../../domain/employee";
import { parseToHighlightedParts } from "src/app/core/utils/parse-to-highlighted-parts";
import { EmployeeService } from "src/app/features/employees/data/employee-service";
import { EmployeesListState } from "./employees-list-state";
import { ToastState } from "../../../../../../core/components/toast-alert/toast-alert.component";
import { ToastsService } from "../../../../../../core/components/toast-alert/services/toast-alert.service";
import { translate } from "@jsverse/transloco";
import { EmployeeListConstants } from "../../common/employees-list-constants";

@Injectable()
export class EmployeesListExecutor extends Executor<
  EmployeesListState,
  EmployeesListAction,
  EmployeesListResultAction
> {
  constructor(
    private employeeService: EmployeeService,
    private toastsService: ToastsService,
  ) {
    super();
  }

  execute(action: EmployeesListAction) {
    switch (action.type) {
      case EmployeesListActionTypes.CHANGE_CREATE_EMPLOYEE_MODAL_VISIBLE:
        this.reduce({
          type: EmployeesListResultActionTypes.CHANGE_CREATE_EMPLOYEE_MODAL_VISIBLE,
          value: action.value
        })
        break;
      case EmployeesListActionTypes.UPDATE_EMPLOYEES_LIST:
        this.handleUpdateEmployeesList()
        break;
      case EmployeesListActionTypes.EDIT_EMPLOYEE:
        this.reduce({
          type: EmployeesListResultActionTypes.EDIT_EMPLOYEE,
          id: action.id
        })
        break;
      case EmployeesListActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY:
        this.reduce({
          type: EmployeesListResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
          value: action.value,
          deleteProps: action.deleteProps
        })
        break
      case EmployeesListActionTypes.DELETE_EMPLOYEE:
        const deleteEmployee = this.getState().employeeDeleteProps
        if(deleteEmployee != null){
          this.reduce({
            type: EmployeesListResultActionTypes.CHANGE_IS_DELETING,
            value: true
          })
          this.employeeService.deleteEmployee(deleteEmployee.id).subscribe({
            next: () => {
              this.toastsService.createToast({
                title: translate(EmployeeListConstants.TRANSLOCO_READ + '.delete-employee-title'),
                description: "",
                state: ToastState.SUCCESS
              });
              this.reduce({
                type: EmployeesListResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
                value: false
              });
              this.reduce({
                type: EmployeesListResultActionTypes.CHANGE_CREATE_EMPLOYEE_MODAL_VISIBLE,
                value: false
              });
              this.reduce({
                type: EmployeesListResultActionTypes.CHANGE_IS_DELETING,
                value: false
              });
              this.handleUpdateEmployeesList();
            },
            error: () => {
              this.toastsService.createToast({
                title: translate( EmployeeListConstants.TRANSLOCO_READ + '.delete-employee-error-title'),
                description: translate( EmployeeListConstants.TRANSLOCO_READ + '.delete-employee-error-description'),
                state: ToastState.ERROR
              })
              this.reduce({
                type: EmployeesListResultActionTypes.CHANGE_IS_DELETING,
                value: false
              })
            }
          })
        }
        break;
      case EmployeesListActionTypes.CHANGE_SEARCH_FIELD:
        this.reduce({
          type: EmployeesListResultActionTypes.CHANGE_SEARCH_FIELD,
          value: action.value,
          filteredEmployees: this.getFilteredEmployees(this.getState().employees, action.value)
        })
        break;
    }
  }

  private getFilteredEmployees(employees: EmployeeSummeryEntity[], filterString: string): EmployeeSummeryEntity[]{
    return employees.filter((employee)=>{
      const fullName = employee.surname + ' ' + employee.name + ' ' + employee.patronymic
      employee.nameParts = parseToHighlightedParts(fullName, filterString)
      employee.emailParts = parseToHighlightedParts(employee.email, filterString)
      return fullName.toLowerCase().includes(filterString.toLowerCase()) ||
        employee.email.toLowerCase().includes(filterString.toLowerCase()) ||
        filterString == ''
    })
  }

  private handleUpdateEmployeesList(){
    this.reduce({
      type: EmployeesListResultActionTypes.CHANGE_IS_LOADING,
      value: true
    })

    this.employeeService.getEmployees()
    .subscribe({
      next: (employeeAll) => {
        const newEmployeesList = employeeAll.employees
          .map<EmployeeSummeryEntity>((element) => {
            const fullName = element.surname + " " + element.name + " " + element.patronymic;
            const searchFieldValue = this.getState().searchFieldValue;
            return {
              id: element.id,
              surname: element.surname,
              name: element.name,
              patronymic: element.patronymic,
              icon: element.avatarUrl,
              email: element.email,
              phoneNumber: element.phoneNumber,
              isMenuVisible: false,
              nameParts: parseToHighlightedParts(fullName, searchFieldValue),
              emailParts: parseToHighlightedParts(element.email, searchFieldValue)
            };
          });
        this.reduce({
          type: EmployeesListResultActionTypes.UPDATE_EMPLOYEES_LIST,
          canCreate: employeeAll.canCreate,
          canEdit: employeeAll.canEdit,
          canDelete: employeeAll.canDelete,
          ownerId: employeeAll.ownerID,
          employees: newEmployeesList,
          filteredEmployees: this.getFilteredEmployees(newEmployeesList, this.getState().searchFieldValue)
        });
        this.reduce({
          type: EmployeesListResultActionTypes.CHANGE_IS_LOADING,
          value: false
        });
      },
      error: ()=>{
        this.reduce({
          type: EmployeesListResultActionTypes.CHANGE_IS_LOADING,
          value: false
        });
        this.toastsService.createToast({
          title: translate(EmployeeListConstants.TRANSLOCO_READ + '.get-employees-list-error-title'),
          description: translate(EmployeeListConstants.TRANSLOCO_READ + '.get-employees-list-error-description'),
          state: ToastState.ERROR
        })
      }
    })
  }
}
