import { Component, Input } from "@angular/core";
import { NgClass } from "@angular/common";

@Component({
  selector: "app-core-page-shading",
  templateUrl: "./page-shading.component.html",
  imports: [
    NgClass
  ],
  standalone: true
})
export class PageShadingComponent {
  @Input() appearanceType: 'base' | 'animate' = "base"
  @Input() className: string | string[] = [];

  mouseDown(event: MouseEvent) {
    event.stopPropagation()
  }
}
