import { Injectable } from '@angular/core';
import { ListType } from "../../../../domain/list-type";
import { NewsSummaryEntity } from "../../../../domain/news-summary-entity";
import { DraftDto } from "../../../../data/dto/draft-dto";

@Injectable()
export class NewsListState {
  readonly listType: ListType = 'grid-cell';
  readonly news: NewsSummaryEntity[] = []
  readonly filteredNews: NewsSummaryEntity[] = []
  readonly searchFieldValue: string = ''
  readonly canCreate: boolean = false;
  readonly canEdit: boolean = false;

  readonly categories: string[] = [
    '1 категория',
    '2 категория',
    '3 категория',
    '4 категория',
    '5 категория',
    '6 категория',
    '7 категория',
    '8 категория',
    '9 категория',
    '10 категория',
    '11 категория',
    '12 категория',
  ]
  readonly selectedCategory: string | 'all-news' = 'all-news'

  readonly isPageLoading: boolean = false;

  readonly openDraftModalVisibility: boolean = false;
  readonly openDraftModalIsLoading: boolean = false;

  readonly draftDto?: DraftDto

  readonly isFullNewsOpen: boolean = false;
  readonly fullNews?: NewsSummaryEntity
}
