import { NewsEditorInfoDto } from "../../../../data/dto/news-editor-info-dto";
import { EditorType } from "./news-editor-state";
import { YooptaEditorComponent } from "../../../../../../core/components/yoopta-editor/presentation/yoopta-editor.component";
import { ListType } from "../../../../domain/list-type";
import { QueryList } from "@angular/core";
import { InputFieldComponent } from "../../../../../../core/components/fields/components/input-field/input-field.component";
import {
  TextareaFieldComponent
} from "../../../../../../core/components/fields/components/textarea-field/textarea-field.component";

export type NewsEditorResultAction =
  | ChangePublishSidebarVisibleResultAction
  | ChangeTitleResultAction
  | ChangeDescriptionResultAction
  | ChangeImageIdResultAction
  | SaveSessionResultAction
  | ChangeAfkModalVisibilityResultAction
  | ChangeAlreadyEditModalVisibilityResultAction
  | InitResultAction
  | ChangeIsLoadingResultAction
  | InitChildrenResultAction
  | ChangePreviewTypeResultAction
  | ChangeIsPageLoadingResultAction
  | ChangeIsEditorLoadingResultAction
  | ChangeIsLoadingAfkModalResultAction

export enum NewsListResultActionTypes {
  CHANGE_PUBLISH_SIDEBAR_VISIBLE,
  CHANGE_TITLE,
  CHANGE_DESCRIPTION,
  CHANGE_IMAGE_ID,
  CHANGE_IS_LOADING,
  SAVE_SESSION,
  CHANGE_AFK_MODAL_VISIBILITY,
  CHANGE_IS_LOADING_AFK_MODAL,
  CHANGE_ALREADY_EDIT_MODAL_VISIBILITY,
  INIT,
  INIT_CHILDREN,
  CHANGE_PREVIEW_TYPE,
  CHANGE_IS_PAGE_LOADING,
  CHANGE_IS_EDITOR_LOADING,
}

export interface ChangeIsLoadingAfkModalResultAction{
  readonly type: NewsListResultActionTypes.CHANGE_IS_LOADING_AFK_MODAL;
  readonly value: boolean
}

export interface ChangeIsEditorLoadingResultAction{
  readonly type: NewsListResultActionTypes.CHANGE_IS_EDITOR_LOADING;
  readonly value: boolean
}

export interface ChangeIsPageLoadingResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING
  readonly value: boolean
}

export interface ChangePreviewTypeResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_PREVIEW_TYPE
  readonly value: ListType
}

export interface InitChildrenResultAction{
  readonly type: NewsListResultActionTypes.INIT_CHILDREN;
  readonly editor?: YooptaEditorComponent
  readonly inputEls?: QueryList<InputFieldComponent | TextareaFieldComponent>
}

export interface ChangeIsLoadingResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_IS_LOADING;
  readonly value: boolean
}

export interface InitResultAction {
  readonly type: NewsListResultActionTypes.INIT;
  readonly newsId: string
  readonly loadedNews: NewsEditorInfoDto,
  readonly editorType: EditorType
}

export interface ChangeAlreadyEditModalVisibilityResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_ALREADY_EDIT_MODAL_VISIBILITY;
  readonly value: boolean
}

export interface ChangeAfkModalVisibilityResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_AFK_MODAL_VISIBILITY;
  readonly value: boolean
}

export interface SaveSessionResultAction {
  readonly type: NewsListResultActionTypes.SAVE_SESSION;
  readonly sessionId: string
  readonly pingIntervalId: number
}

export interface ChangePublishSidebarVisibleResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_PUBLISH_SIDEBAR_VISIBLE
  readonly value: boolean
}

export interface ChangeTitleResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_TITLE,
  readonly value: string,
  readonly error: string | null
}

export interface ChangeDescriptionResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_DESCRIPTION,
  readonly value: string
  readonly error: string | null
}

export interface ChangeImageIdResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_IMAGE_ID,
  readonly value: string
}
