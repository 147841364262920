import { Executor, Reducer } from "src/app/core/mvi/store";
import { EmployeesRolesAction, EmployeesRolesActionTypes } from "./employees-roles-action";
import { EmployeesRolesResultAction, EmployeesRolesResultActionTypes } from "./employees-roles-result-action";
import { Injectable } from "@angular/core";
import { EmployeesRolesState } from "./employees-roles-state";
import { RoleService } from "src/app/core/services/role-service/role-service";
import { EmployeesConstants } from "../../../../common/employees-constants";
import { parseToHighlightedParts } from "src/app/core/utils/parse-to-highlighted-parts";
import { RoleSummeryEntity } from "../../../../domain/role";
import { ToastState } from "../../../../../../core/components/toast-alert/toast-alert.component";
import { ToastsService } from "../../../../../../core/components/toast-alert/services/toast-alert.service";
import { translate, TranslocoService } from "@jsverse/transloco";
import { EmployeesRolesConstants } from "../../common/employees-roles-constants";

@Injectable()
export class EmployeesRolesExecutor extends Executor<
  EmployeesRolesState,
  EmployeesRolesAction,
  EmployeesRolesResultAction
> {
  constructor(
    private roleService: RoleService,
    private toastsService: ToastsService,
    private translocoService: TranslocoService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<EmployeesRolesState, EmployeesRolesResultAction>,
    getState: () => EmployeesRolesState,
    onReduced: (state: EmployeesRolesState) => void
  ) {
    super.init(reducer, getState, onReduced);
    this.translocoService.langChanges$.subscribe(() => this.execute({
      type: EmployeesRolesActionTypes.FILTER_ROLES,
      filterStr: this.getState().searchFieldValue
    }))
  }

  execute(action: EmployeesRolesAction) {
    switch (action.type) {
      case EmployeesRolesActionTypes.UPDATE_ROLES:
        this.handleUpdateRoles()
        break;
      case EmployeesRolesActionTypes.CHANGE_CREATE_ROLE_MODAL_VISIBLE:
        this.reduce({
          type: EmployeesRolesResultActionTypes.CHANGE_CREATE_ROLE_MODAL_VISIBLE,
          value: action.value
        })
        break;
      case EmployeesRolesActionTypes.FILTER_ROLES:
        this.reduce({
          type: EmployeesRolesResultActionTypes.FILTER_ROLES,
          filterString: action.filterStr,
          filteredRoles: this.filterRoles(action.filterStr, this.getState().roles)
        })
        break;
      case EmployeesRolesActionTypes.DELETE_ROLE:
        const deleteProps = this.getState().roleDeleteProps
        if(deleteProps)
        {
          this.reduce({
            type: EmployeesRolesResultActionTypes.CHANGE_IS_DELETING,
            value: true
          })
          this.roleService.deleteRole(deleteProps.id).subscribe({
            next: () => {
              this.toastsService.createToast({
                title: translate(EmployeesRolesConstants.TRANSLOCO_READ + '.role-deleted'),
                description: "",
                state: ToastState.SUCCESS
              });
              this.execute({
                type: EmployeesRolesActionTypes.UPDATE_ROLES
              });
              this.reduce({
                type: EmployeesRolesResultActionTypes.CHANGE_IS_DELETING,
                value: false
              });
              this.execute({
                type: EmployeesRolesActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
                value: false
              });
            },
            error: err => {
              this.toastsService.createToast({
                title: translate(EmployeesRolesConstants.TRANSLOCO_READ + '.delete-role-error-title'),
                description: translate(EmployeesRolesConstants.TRANSLOCO_READ + '.delete-role-error-description'),
                state: ToastState.ERROR
              })
              this.reduce({
                type: EmployeesRolesResultActionTypes.CHANGE_IS_DELETING,
                value: false
              });
            }
          })
        }
        break;

      case EmployeesRolesActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY:
        this.reduce({
          type: EmployeesRolesResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
          value: action.value,
          deleteProps: action.deleteProps
        })
        break

      case EmployeesRolesActionTypes.EDIT_ROLE:
        this.reduce({
          type: EmployeesRolesResultActionTypes.EDIT_ROLE,
          id: action.id
        })
        break;
    }
  }

  private filterRoles(filterStr: string, roles: RoleSummeryEntity[]): RoleSummeryEntity[] {
    return roles.filter((role) => {
      const name = role.canEdit ? role.name : translate(EmployeesConstants.BASE_ROLES_TRANSLOCO_READ + '.' + role.name);
      role.nameParts = parseToHighlightedParts(name, filterStr);
      return role.nameParts.length > 1 || filterStr == "";
    })
  }

  private handleUpdateRoles(){
    this.reduce({
      type: EmployeesRolesResultActionTypes.CHANGE_IS_LOADING,
      value: true
    })
    this.roleService.getRoles().subscribe({
      next: (roles) => {
        const mappedRoles = roles.items.map<RoleSummeryEntity>((roleDto) => {
          if (EmployeesConstants.BASE_ROLES.has(roleDto.name)) {
            const baseData = EmployeesConstants.BASE_ROLES.get(roleDto.name)!;
            return {
              id: roleDto.id,
              name: baseData.name,
              description: baseData.description,
              nameParts: [],
              canEdit: roleDto.isCustom
            };
          }

          return {
            id: roleDto.id,
            name: roleDto.name,
            description: roleDto.description,
            nameParts: [],
            canEdit: roleDto.isCustom
          };
        });
        this.reduce({
          type: EmployeesRolesResultActionTypes.UPDATE_ROLES,
          canCreate: roles.canCreate,
          canEdit: roles.canEdit,
          canDelete: roles.canDelete,
          roles: mappedRoles,
          filteredRoles: this.filterRoles(this.getState().searchFieldValue, mappedRoles)
        });
        this.reduce({
          type: EmployeesRolesResultActionTypes.CHANGE_IS_LOADING,
          value: false
        });
      },
      error: () => {
        this.toastsService.createToast({
          title: translate(EmployeesRolesConstants.TRANSLOCO_READ + '.get-roles-error-title'),
          description: translate(EmployeesRolesConstants.TRANSLOCO_READ + '.get-roles-error-description'),
          state: ToastState.ERROR
        })
        this.reduce({
          type: EmployeesRolesResultActionTypes.CHANGE_IS_LOADING,
          value: false
        });
      }
    })
  }
}
