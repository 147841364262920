import { ListType } from "../../../../domain/list-type";
import { NewsSummaryEntity } from "../../../../domain/news-summary-entity";
import { DraftDto } from "../../../../data/dto/draft-dto";

export type NewsListResultAction =
  | ChangeListTypeResultAction
  | LoadListResultAction
  | ChangeDraftResultAction
  | OpenFullNewsResultAction
  | CloseFullNewsResultAction
  | ChangeOpenDraftModalVisibilityResultAction
  | ChangeOpenDraftModalIsLoadingResultAction
  | ChangeIsPageLoadingResultAction
  | UpdateNewsResultAction
  | ChangeCategoryResultAction
  | ChangeSearchFieldResultAction

export enum NewsListResultActionTypes {
  CHANGE_LIST_TYPE,
  LOAD_LIST,
  CHANGE_DRAFT,
  CHANGE_IS_PAGE_LOADING,
  CHANGE_OPEN_DRAFT_MODAL_VISIBILITY,
  CHANGE_OPEN_DRAFT_MODAL_IS_LOADING,
  OPEN_FULL_NEWS,
  CLOSE_FULL_NEWS,
  UPDATE_NEWS_LIST,
  CHANGE_CATEGORY,
  CHANGE_SEARCH_FIELD,
}

export interface ChangeSearchFieldResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_SEARCH_FIELD,
  readonly value: string
  readonly filteredNewsList: NewsSummaryEntity[]
}

export interface ChangeCategoryResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_CATEGORY,
  readonly id: string
}

export interface UpdateNewsResultAction {
  readonly type: NewsListResultActionTypes.UPDATE_NEWS_LIST
  readonly newsList: NewsSummaryEntity[]
}

export interface OpenFullNewsResultAction {
  readonly type: NewsListResultActionTypes.OPEN_FULL_NEWS
  readonly news: NewsSummaryEntity
}

export interface CloseFullNewsResultAction {
  readonly type: NewsListResultActionTypes.CLOSE_FULL_NEWS
}


export interface ChangeOpenDraftModalVisibilityResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_OPEN_DRAFT_MODAL_VISIBILITY
  readonly value: boolean
}

export interface ChangeOpenDraftModalIsLoadingResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_OPEN_DRAFT_MODAL_IS_LOADING
  readonly value: boolean
}

export interface ChangeIsPageLoadingResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING
  readonly value: boolean
}

export interface ChangeDraftResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_DRAFT
  readonly draft?: DraftDto
}

export interface ChangeListTypeResultAction {
  readonly type: NewsListResultActionTypes.CHANGE_LIST_TYPE
  readonly listType: ListType
}

export interface LoadListResultAction {
  readonly type: NewsListResultActionTypes.LOAD_LIST
  readonly canCreate: boolean
  readonly canEdit: boolean
  readonly news: NewsSummaryEntity[]
  readonly filteredNewsList: NewsSummaryEntity[]
}
