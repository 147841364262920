<div
  *ngIf="toggleItems.length > 0"
  class="price-toggle"
>
  <div *ngIf="selectedId != ''" #selectedBg class="absolute price-toggle-button bg-white"></div>

  <button
    *ngFor="let toggleItem of toggleItems"
    class="price-toggle-button z-[1] flex justify-center items-center"
    (click)="onToggle(toggleItem.id)"
    [id]="TOGGLE_ITEM_ID + toggleItem.id"
    [style.width]="'calc(100% / ' + toggleItems.length"
  >
    <p
      class="text-tt-font-size_l text-tt-text-main w-full truncate"
    >{{toggleItem.text}}<span class="text-tt-primary">{{toggleItem.suffix}}</span></p>
  </button>
</div>
