import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NewsSummaryEntity } from "../../../../../domain/news-summary-entity";
import { NewsConstants } from "../../../../../common/news-constants";
import { NewsNavigator } from "../../../../../navigator/news-navigator.service";
import { stringToRgb } from "../../../../../../../core/utils/string-to-rgb";
import { TooltipDirective } from "../../../../../../../core/directives/tooltip.directive";
import { NewsListConstants } from "../../../common/news-list-constants";

@Component({
  selector: 'app-news-full-news',
  templateUrl: './full-news.component.html',
  styleUrls: ['./full-news.component.scss']
})
export class FullNewsComponent {
  @Input({ required: true }) news!: NewsSummaryEntity
  @Output() onClose = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @ViewChild('linkTooltip', {read: TooltipDirective}) linkTooltipEl!: TooltipDirective;

  isPopupOpen: boolean = false;
  isEditorLoading: boolean = true;

  protected readonly NewsConstants = NewsConstants;

  constructor(
    private newsNavigator: NewsNavigator) {
  }

  popupElementClicked(id: string): void {
    switch (id){
      case 'edit':
        this.newsNavigator.navigateToEdit(this.news.id)
        break
      case 'delete':
        this.onDelete.emit()
        break
    }
  }

  copyLink(){
    this.newsNavigator.copyLinkToNews(this.news.id)
    this.linkTooltipEl.showTooltip()
  }

  protected readonly stringToRgb = stringToRgb;
  protected readonly NewsListConstants = NewsListConstants;
}
