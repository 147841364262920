<app-core-page-shading
  appearanceType="animate"
  className="bg-black bg-opacity-[0.85]"
  *transloco="let t; read: NewsListConstants.TRANSLOCO_READ"
>
  <div class="flex flex-col w-screen h-screen">
    <button
      class="h-[40px] flex justify-end shrink-0"
      (click)="onClose.emit()"
    >
      <div class="size-[40px] text-[#DBDBDE] flex justify-center items-center rounded-full">
        <svg-x-mark class="block size-[24px]"/>
      </div>
    </button>
    <div class="flex-grow bg-white flex flex-col overflow-hidden appearance">
      <div class="w-full shrink-0 flex justify-center px-[40px]">
        <div class="max-w-[720px] w-full flex py-[12px]">
          <app-core-avatar
            size="lx"
            variant="primary"
            [placeholder]="'TT'"
            [bgRGB]="stringToRgb('TT')"
          />
          <div class="ml-[12px] flex-grow py-[2px]">
            <p class="text-tt-text-main font-medium text-tt-font-size_l line-clamp-1">{{ news.title }}</p>
            <p class="text-tt-text-secondary text-tt-font-size_m line-clamp-1">{{ t('published-at', { date: news.dateStr }) }}</p>
          </div>
          <div class="ml-[16px] max-w-[96px] flex">
            <app-core-button-icon
              [tooltip]="t('link-copied')"
              (onClick)="copyLink()"
              #linkTooltip
            >
              <svg-link/>
            </app-core-button-icon>
            <div>
              <app-core-button-icon
                (onClick)="isPopupOpen = !isPopupOpen"
              >
                <svg-triple-dot/>
              </app-core-button-icon>

              <app-core-action-menu
                *ngIf="isPopupOpen"
                [sections]="NewsConstants.FULL_NEWS_ACTION_MENU_SECTIONS"
                [translocoRead]="NewsListConstants.TRANSLOCO_READ"
                (elementClicked)="popupElementClicked($event)"
                appPopup
                (popupClose)="isPopupOpen = false"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="w-full h-[1px] bg-tt-border-default"></div>

      <div
        class="flex-grow overflow-y-auto tiny-scrollbar w-full px-[40px]"
        id="yoopta-editor-scroll"
      >
        <div
          class="w-full flex justify-center mt-[20px]"
          *ngIf="isEditorLoading"
        >
          <mat-spinner class="w-[48px] h-[48px]"></mat-spinner>
        </div>

        <app-yoopta-editor
          class="flex justify-center"
          [class.invisible]="isEditorLoading"
          [longreadId]="news.longreadId"
          [title]="news.title"
          [isHaveTitle]="true"
          [isReadOnly]="true"
          (startLoading)="isEditorLoading = true"
          (endLoading)="isEditorLoading = false"
        />
      </div>
    </div>
  </div>

</app-core-page-shading>
