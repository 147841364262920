import { RoleUsersState } from "./role-users-state.service";
import { RoleUsersAction, RoleUsersActionTypes } from "./role-users-action";
import { RoleUsersResultAction, RoleUsersResultActionTypes } from "./role-users-result-action";
import { Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { ShortEmployee } from "../../../../../../../../core/components/select-user-modal/select-user-modal.component";
import { parseToHighlightedParts } from "../../../../../../../../core/utils/parse-to-highlighted-parts";
import { EmployeeService } from "../../../../../../data/employee-service";
import { ToastsService } from "../../../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../../../core/components/toast-alert/toast-alert.component";
import { translate } from "@jsverse/transloco";
import { RoleConstants } from "../../../../common/role-constants";

@Injectable()
export class RoleUsersExecutor extends Executor<
  RoleUsersState,
  RoleUsersAction,
  RoleUsersResultAction
> {

  constructor(
    private employeeService: EmployeeService,
    private toastsService: ToastsService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<RoleUsersState, RoleUsersResultAction>,
    getState: () => RoleUsersState,
    onReduced: (state: RoleUsersState) => void,
  ) {
    super.init(reducer, getState, onReduced);
  }

  execute(action: RoleUsersAction) {
    switch (action.type) {
      case RoleUsersActionTypes.INIT:
        this.handleInit(action.roleId);
        break;
      case RoleUsersActionTypes.CHANGE_ADD_USERS_MODAL_VISIBILITY:
        this.reduce({
          type: RoleUsersResultActionTypes.CHANGE_ADD_USERS_MODAL_VISIBILITY,
          value: action.value
        })
        break;
      case RoleUsersActionTypes.ADD_USERS:
        this.handleAddUsers(action.users)
        break;
      case RoleUsersActionTypes.DELETE_USER:
        this.reduce({
          type: RoleUsersResultActionTypes.DELETE_USER,
          user: action.user
        })
        break
      case RoleUsersActionTypes.CLOSE_DELETE_MODAL:
        this.reduce({
          type: RoleUsersResultActionTypes.CHANGE_DELETE_USER_MODAL_VISIBILITY,
          value: false
        })
        break
      case RoleUsersActionTypes.CHANGE_SEARCH_FIELD:
        this.handleFilterUsers(action.value)
        break
      case RoleUsersActionTypes.DELETE_CONFIRM:
        this.handleDeleteConfirm()
        break;
    }
  }
  private handleInit(roleId: string){
    this.reduce({
      type: RoleUsersResultActionTypes.INIT,
      roleId: roleId
    })
    this.reduce({
      type: RoleUsersResultActionTypes.CHANGE_IS_LOADING,
      value: true
    })
    this.employeeService.getEmployeesByRole(roleId).subscribe({
      next: response => {
        this.reduce({
          type: RoleUsersResultActionTypes.CHANGE_USERS,
          users: response.employees.map((employees) => {
            return {
              id: employees.id,
              name: employees.name,
              surname: employees.surname,
              patronymic: employees.patronymic,
              email: employees.email,
              isSelected: false,
              nameParts: parseToHighlightedParts(employees.name),
              emailParts: parseToHighlightedParts(employees.email)
            }
          })
        });
        this.handleFilterUsers(this.getState().filterString)
        this.reduce({
          type: RoleUsersResultActionTypes.CHANGE_IS_LOADING,
          value: false
        })
      },
      error: () => {
        this.toastsService.createToast({
          title: translate(RoleConstants.TRANSLOCO_READ + '.get-employees-by-role-error-title'),
          description: translate(RoleConstants.TRANSLOCO_READ + '.get-employees-by-role-error-description'),
          state: ToastState.ERROR
        })
        this.reduce({
          type: RoleUsersResultActionTypes.CHANGE_IS_LOADING,
          value: false
        })
      }
    })
  }

  private handleDeleteConfirm(){
    const state = this.getState()
    if(state.userToDelete){
      this.reduce({
        type: RoleUsersResultActionTypes.CHANGE_IS_LOADING_DELETE_MODAL,
        value: true
      })
      this.employeeService.removeEmployeesRole(state.roleId, [state.userToDelete.id]).subscribe({
        next: () => {
          this.reduce({
            type: RoleUsersResultActionTypes.CHANGE_USERS,
            users: state.users.slice().filter(user => user.id !== state.userToDelete?.id)
          })
          this.handleFilterUsers(this.getState().filterString)
          this.reduce({
            type: RoleUsersResultActionTypes.CHANGE_DELETE_USER_MODAL_VISIBILITY,
            value: false
          })
          this.reduce({
            type: RoleUsersResultActionTypes.CHANGE_IS_LOADING_DELETE_MODAL,
            value: false
          })
        },
        error: () => {
          this.reduce({
            type: RoleUsersResultActionTypes.CHANGE_IS_LOADING_DELETE_MODAL,
            value: false
          })
        }
      })
    }
  }

  private handleAddUsers(users: ShortEmployee[]){
    const userIds = users.map<number>(user => {return user.id})
    this.reduce({
      type: RoleUsersResultActionTypes.CHANGE_IS_LOADING_SELECT_USERS_MODAL,
      value: true
    })
    this.employeeService.addEmployeesRole(this.getState().roleId, userIds).subscribe({
      next: () => {
        this.reduce({
          type: RoleUsersResultActionTypes.CHANGE_USERS,
          users: this.getState().users.slice().concat(users)
        })
        this.handleFilterUsers(this.getState().filterString)
        this.reduce({
          type: RoleUsersResultActionTypes.CHANGE_IS_LOADING_SELECT_USERS_MODAL,
          value: false
        })
      },
      error: () => {
        this.toastsService.createToast({
          title: translate(RoleConstants.TRANSLOCO_READ + '.add-role-to-employees-error-title'),
          description: translate(RoleConstants.TRANSLOCO_READ + '.add-role-to-employees-error-description'),
          state: ToastState.ERROR
        })
        this.reduce({
          type: RoleUsersResultActionTypes.CHANGE_IS_LOADING_SELECT_USERS_MODAL,
          value: false
        })
      }
    })

  }

  private handleFilterUsers(filterString: string) {
    this.reduce({
      type: RoleUsersResultActionTypes.FILTER_USERS,
      filterString: filterString,
      filteredUsers: this.getState().users.filter(user => {
        const fullName = user.surname + ' ' + user.name + (user.patronymic ? ' ' + user.patronymic : '')
        if(fullName.toLowerCase().includes(filterString.toLowerCase())
          || user.email.toLowerCase().includes(filterString.toLowerCase())
        ){
          user.nameParts = parseToHighlightedParts(fullName, filterString)
          user.emailParts = parseToHighlightedParts(user.email, filterString)
          return true
        }
        return false
      })
    })
  }
}
