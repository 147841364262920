import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Validator } from "../../../../../../../core/validators/validator";
import { RoleConstants } from "../../../common/role-constants";
import { translate, TranslocoService } from "@jsverse/transloco";
import { EmployeesRolesConstants } from "../../../../employees-roles/common/employees-roles-constants";
import { EmployeesConstants } from "../../../../../common/employees-constants";

@Component({
  selector: "app-role-main",
  templateUrl: "./role-main.component.html",
  styleUrls: ["./role-main.component.scss"]
})
export class RoleMainComponent implements OnChanges {
  @Input() isReadOnly: boolean = true;
  @Input() isCustom: boolean = true;
  @Input() data: RoleMainEntity = {
    name: "",
    description: ""
  };

  @Output() onSave = new EventEmitter<RoleMainEntity>();

  initData: RoleMainEntity = {
    name: "",
    description: ""
  };

  nameError: string | null = null
  descriptionError: string | null = null

  constructor(
    @Inject("NewRoleNameValidator")
    private roleNameValidator: Validator,
    @Inject("NewRoleDescriptionValidator")
    private roleDescriptionValidator: Validator,
    private translocoService: TranslocoService,
  )
  {
    this.translocoService.langChanges$.subscribe(()=> {
      if(this.nameError != null){
        this.nameChange(this.data.name)
      }
      if(this.descriptionError != null){
        this.descriptionChange(this.data.description)
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data']){
      this.initData = {
        name: this.data.name,
        description: this.data.description
      }
      this.decline()
    }
  }

  nameChange(value: string) {
    const error = this.roleNameValidator.validate(value)
    this.nameError = error ? translate(RoleConstants.TRANSLOCO_READ + '.' + error, {maxLength: EmployeesRolesConstants.ROLE_NAME_MAX_LENGTH}) : null
    this.data.name = value
  }

  descriptionChange(value: string) {
    const error = this.roleDescriptionValidator.validate(value)
    this.descriptionError = error ? translate(RoleConstants.TRANSLOCO_READ + '.' + error, {maxLength: EmployeesRolesConstants.ROLE_DESCRIPTION_MAX_LENGTH}) : null
    this.data.description = value
  }

  save(){
    this.descriptionError = this.roleDescriptionValidator.validate(this.data.description)
    this.nameError = this.roleNameValidator.validate(this.data.name)

    if(!this.nameError && !this.descriptionError){
      this.onSave.emit(this.data)
    }
  }

  decline(){
    this.data = {
      name: this.initData.name,
      description: this.initData.description,
    }
  }

  protected readonly RoleConstants = RoleConstants;
  protected readonly EmployeesConstants = EmployeesConstants;
}

export interface RoleMainEntity{
  name: string,
  description: string,
}
