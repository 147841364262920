import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  NewsListResultAction,
  NewsListResultActionTypes
} from "./news-list-result-action";
import { NewsListState } from './news-list-state';

@Injectable()
export class NewsListReducer
  implements Reducer<NewsListState, NewsListResultAction>
{
  reduce(state: NewsListState, action: NewsListResultAction): NewsListState {
    switch (action.type) {
      case NewsListResultActionTypes.CHANGE_LIST_TYPE:
        return {...state, listType: action.listType}
      case NewsListResultActionTypes.LOAD_LIST:
        return {...state,
          canCreate: action.canCreate,
          canEdit: action.canEdit,
          news: action.news,
          filteredNews: action.filteredNewsList
        }
      case NewsListResultActionTypes.OPEN_FULL_NEWS:
        return {...state, isFullNewsOpen: true, fullNews: action.news}
      case NewsListResultActionTypes.CLOSE_FULL_NEWS:
        return {...state, isFullNewsOpen: false, fullNews: undefined}
      case NewsListResultActionTypes.CHANGE_DRAFT:
        return {...state, draftDto: action.draft}
      case NewsListResultActionTypes.CHANGE_IS_PAGE_LOADING:
        return {...state, isPageLoading: action.value}
      case NewsListResultActionTypes.CHANGE_OPEN_DRAFT_MODAL_IS_LOADING:
        return {...state, openDraftModalIsLoading: action.value}
      case NewsListResultActionTypes.CHANGE_OPEN_DRAFT_MODAL_VISIBILITY:
        return {...state, openDraftModalVisibility: action.value}
      case NewsListResultActionTypes.UPDATE_NEWS_LIST:
        return {...state, news: action.newsList}
      case NewsListResultActionTypes.CHANGE_CATEGORY:
        return {...state, selectedCategory: action.id}
      case NewsListResultActionTypes.CHANGE_SEARCH_FIELD:
        return {...state, searchFieldValue: action.value, filteredNews: action.filteredNewsList}
    }
  }
}
