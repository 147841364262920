<div
  class="max-w-full flex flex-wrap overflow-hidden"
>
  <div
    *ngFor="let skeletonWidth of shownSkeletons; let i = index"
    class="h-[48px] flex items-center px-[12px] border-tt-border-default bg-[#FAFAFA] border-y"
    [style.width]="skeletonWidth + 'px'"
    [ngClass]="{
      'rounded-l-[8px] border-l': i === 0,
      'border-r rounded-r-[8px]': i === shownSkeletons.length -1
    }"
  >
    <div class="w-full bg-[#F1F1F1] rounded-[12px] h-[24px]"></div>
  </div>
</div>

